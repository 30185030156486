<div
  class="lg:flex flex-row absolute top-0 left-0 w-full z-5 align-items-center justify-content-between bg px-6"
>
  <div
    class="h-full hidden lg:flex flex-row align-items-center gap-4"
    (click)="goHome()"
  >
    <img
      src="../../../../../assets/images/logo.png"
      class="h-full py-3 cursor-pointer"
      alt="Logo"
    />
    <div class="flex flex-column align-items-start cursor-pointer">
      <span class="label1 font-bold text-4xl">NOVAFOODIES</span>
      <span class="text-white text-2xl font-light">Market Place</span>
    </div>
  </div>
  <div
    class="h-full flex flex-row align-items-center justify-content-center gap-4"
  >
    <p-button
      (click)="onButtonClick($event)"
      [icon]="isOverlayPanelVisible ? 'pi pi-chevron-up' : 'pi pi-chevron-down'"
      [label]="(selectedLanguage | translate).slice(0, 3)"
      [styleClass]="'language-custom'"
    />
    <p-overlayPanel #op>
      <div class="flex flex-column gap-3 w-5rem">
        <div
          *ngFor="let lang of languages"
          class="cursor-pointer"
          (click)="changeLanguageHandler(lang.value, $event)"
        >
          <span class="font-medium text-900 block mb-2">{{
            lang.value | translate
          }}</span>
        </div>
      </div>
    </p-overlayPanel>
    <div class="flex flex-column align-items-end gap-1">
      <span class="text-white text-xl">{{ name }}</span>
      <span
        *ngIf="role === RoleEnum.PRODUCER"
        class="label1 text-xl font-light"
        >{{ role | titlecase }}</span
      >
      <span *ngIf="role === RoleEnum.ADMIN" class="label1 text-xl font-light"
        >{{ 'shared.admin' | translate }} -
        {{ storage.getProducer()?.name | titlecase }}</span
      >
    </div>
    <img
      *ngIf="imageUrl"
      [src]="imageUrl"
      class="h-full py-5 cursor-pointer"
      alt="Logo"
      (click)="menu.toggle($event)"
    />
    <p-avatar
      *ngIf="!imageUrl"
      icon="pi pi-user"
      styleClass="mr-2 my-4 cursor-pointer"
      size="large"
      shape="circle"
      (click)="menu.toggle($event)"
    />
    <p-menu styleClass="logout-text" #menu [model]="items" [popup]="true" />
  </div>
</div>
