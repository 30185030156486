export enum RoutingResourceEnum {
  LOGIN = 'login',
  PRODUCER_SELECTION = 'producer-selection',
  HOME = '',
  PRODUCTS = 'products',
  PRODUCTION_SITES = 'production-sites',
  BATCHES = 'batches',
  CUSTOMERS = 'customers',
  SHIPMENTS = 'shipment',
  TRACE_BATCH = 'trace-batch',
}
