<p-dialog
  [modal]="true"
  [(visible)]="visible"
  [style]="{ width: '60rem' }"
  [breakpoints]="{
    '1199px': '65vw',
    '575px': '90vw',
  }"
  (onHide)="closeDialog()"
>
  <ng-template pTemplate="header">
    <div class="inline-flex align-items-center justify-content-start gap-2">
      <i
        *ngIf="type === ExtensionsEnum.IMAGE"
        class="pi pi-image text-white"
      ></i>
      <i
        *ngIf="type === ExtensionsEnum.PDF"
        class="pi pi-file-o text-white"
      ></i>
      <span class="text-white text-2xl white-space-nowrap">
        {{ title }}
      </span>
    </div>
  </ng-template>
  <ng-template pTemplate="content">
    <ng-content></ng-content>
  </ng-template>
</p-dialog>
