import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DialogModule } from 'primeng/dialog';
import { FloatLabelModule } from 'primeng/floatlabel';
import {
  AutoCompleteCompleteEvent,
  AutoCompleteModule,
  AutoCompleteSelectEvent,
} from 'primeng/autocomplete';
import { TranslateModule } from '@ngx-translate/core';
import { Button } from 'primeng/button';
import { FormsModule } from '@angular/forms';
import { NgClass } from '@angular/common';
import { RoutingResourceEnum } from '../../../core/enums/routing-resource-enum';
import { Router } from '@angular/router';
import { ResponseBatchDto } from '../../../features/batch/dto/response-batch.dto';

@Component({
  selector: 'app-trace-search-custom',
  standalone: true,
  imports: [
    DialogModule,
    FloatLabelModule,
    AutoCompleteModule,
    TranslateModule,
    Button,
    FormsModule,
    NgClass,
  ],
  templateUrl: './trace-search-custom.component.html',
  styleUrl: './trace-search-custom.component.scss',
})
export class TraceSearchCustomComponent {
  @Input() dialogVisible: boolean = false;
  @Input() batches: ResponseBatchDto[] = [];
  @Input() inputInvalid: boolean = false;
  @Input() selectedBatch: ResponseBatchDto | null = null;
  @Output() dialogVisibleChange = new EventEmitter<boolean>();
  @Output() traceBatch = new EventEmitter<ResponseBatchDto>();
  selectedBatchFromList!: boolean;
  filteredBatches: ResponseBatchDto[] = [];

  constructor(private router: Router) {}

  filterBatch(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;
    if (query.length >= 3) {
      filtered = this.batches.filter((batch) =>
        batch.code.toLowerCase().startsWith(query.toLowerCase()),
      );
      this.filteredBatches = filtered;
    } else {
      this.filteredBatches = [];
    }
  }

  onTraceBatch() {
    if (this.selectedBatch && typeof this.selectedBatch !== 'string') {
      void this.router.navigate([
        `${RoutingResourceEnum.TRACE_BATCH}/${this.selectedBatch._id}`,
      ]);
    }
  }

  onBatchSelect(event: AutoCompleteSelectEvent) {
    if (event.value) {
      this.selectedBatchFromList = true;
    }
  }

  closeDialog() {
    this.dialogVisible = false;
    this.dialogVisibleChange.emit(this.dialogVisible);
  }
}
