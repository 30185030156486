import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DialogModule } from 'primeng/dialog';
import { NgIf } from '@angular/common';
import { ExtensionsEnum } from '../../../core/enums/extensions-enum';

@Component({
  selector: 'app-dialog-attachment-custom',
  standalone: true,
  imports: [DialogModule, NgIf],
  templateUrl: './dialog-attachment-custom.component.html',
  styleUrl: './dialog-attachment-custom.component.scss',
})
export class DialogAttachmentCustomComponent {
  @Input() title: string = '';
  @Input() visible: boolean = false;
  @Input() type: string = '';
  @Output() onClose: EventEmitter<void> = new EventEmitter<void>();

  closeDialog() {
    this.visible = false;
    this.onClose.emit();
  }

  protected readonly ExtensionsEnum = ExtensionsEnum;
}
