import {
  Component,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Table, TableModule } from 'primeng/table';
import {
  DatePipe,
  NgClass,
  NgForOf,
  NgIf,
  NgSwitch,
  NgSwitchCase,
} from '@angular/common';
import { ObjectUtils } from 'primeng/utils';
import { FilterSearchCustomComponent } from '../filter-search-custom/filter-search-custom.component';
import { FormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { IndexResponseInterface } from '../../../core/models/index-response.interface';
import { TableColumnInterface } from './table-custom.interface';
import { TableColumnType } from '../../../core/enums/table-column-type';
import { TooltipModule } from 'primeng/tooltip';
import { TruncatePipe } from '../../../core/pipe/truncate-pipe/truncate.pipe';
import { SkeletonModule } from 'primeng/skeleton';

@Component({
  selector: 'app-table-custom[columns][data][countLabel]',
  standalone: true,
  imports: [
    TableModule,
    NgForOf,
    NgIf,
    NgClass,
    FilterSearchCustomComponent,
    FormsModule,
    InputTextModule,
    TranslateModule,
    NgSwitch,
    NgSwitchCase,
    TooltipModule,
    TruncatePipe,
    DatePipe,
    SkeletonModule,
  ],
  templateUrl: './table-custom.component.html',
  styleUrl: './table-custom.component.scss',
})
export class TableCustomComponent<T extends IndexResponseInterface>
  implements OnInit
{
  @Input() columns!: TableColumnInterface<T>[];
  @Input() data!: T[];
  @Input() countLabel!: string;
  @ViewChild('innerTable') innerTable!: Table;
  scrollHeight!: string;

  constructor(private translateService: TranslateService) {
    this.setScrollHeight();
  }

  ngOnInit() {}

  setScrollHeight() {
    const windowHeight = window.innerHeight;

    if (windowHeight < 700) {
      this.scrollHeight = '200px';
    } else {
      this.scrollHeight = '300px';
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.setScrollHeight();
  }

  get rowsLength(): number {
    return this.data ? this.data.length : 0;
  }

  getFieldData(data: any, field: any): any {
    return ObjectUtils.resolveFieldData(data, field);
  }

  shouldShowTooltip(text: string, maxLength: number = 50): string | undefined {
    return text.length > maxLength ? text : undefined;
  }

  getFields(): string[] {
    return this.columns
      .filter((i) => i.field && !i.icon)
      .map((i) => i.field as string);
  }

  applyFilterGlobal($event: any) {
    this.innerTable.filterGlobal($event, 'contains');
  }

  onIconClick(col: TableColumnInterface<T>, rowData: T): void {
    if (col.action) {
      col.action(rowData);
    }
  }

  shouldShowIcon(col: TableColumnInterface<T>, rowData: T): boolean {
    return !col.showCondition || col.showCondition(rowData);
  }

  getEnumKey(enumName: string | undefined, value: string): string {
    return this.translateService.instant(`${enumName}.label.${value}`);
  }

  getTranslationKey(translationkey: string | undefined, value: string): string {
    return (translationkey as string).replace('{value}', value);
  }

  protected readonly TableColumnType = TableColumnType;
  protected readonly Array = Array;
}
