import { Component, Input } from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-label-description-custom',
  standalone: true,
  imports: [NgClass],
  templateUrl: './label-description-custom.component.html',
  styleUrl: './label-description-custom.component.scss',
})
export class LabelDescriptionCustomComponent {
  @Input() title!: string;
  @Input() subtitle!: string;
  @Input() icon!: string;
}
