<p-dialog
  [modal]="true"
  [(visible)]="dialogVisible"
  [style]="{ width: '30rem' }"
  [breakpoints]="{ '1199px': '55vw', '575px': '70vw' }"
  styleClass="custom-dialog-header"
  (onHide)="closeDialog()"
>
  <ng-template pTemplate="header">
    <div class="inline-flex align-items-center justify-content-start gap-2">
      <i class="pi pi-search text-white"></i>
      <span class="text-white text-2xl white-space-nowrap">
        {{ 'shared.trace-batch-header' | translate }}
      </span>
    </div>
  </ng-template>
  <ng-template pTemplate="content">
    <div
      class="flex flex-column align-items-center py-2 autocomplete-container"
    >
      <div class="w-full p-fluid md:w-8">
        <p-floatLabel class="w-full">
          <p-autoComplete
            [(ngModel)]="selectedBatch"
            [dropdown]="true"
            [suggestions]="filteredBatches"
            (completeMethod)="filterBatch($event)"
            (onSelect)="onBatchSelect($event)"
            optionLabel="code"
            styleClass="custom-icon-autocomplete"
            [ngClass]="{ 'ng-invalid': inputInvalid }"
          >
            <ng-template pTemplate="dropdownicon">
              <i class="pi pi-search"></i>
            </ng-template>
            <ng-template pTemplate="empty">
              <span>{{ 'features.homepage.no-batch-found' | translate }}</span>
            </ng-template>
            <ng-template pTemplate="item" let-selectedItem>
              {{ selectedItem.code }}
            </ng-template>
          </p-autoComplete>
          <label>{{ 'features.homepage.batch-number' | translate }}</label>
        </p-floatLabel>
      </div>
      <small class="text-danger">
        {{ 'features.homepage.dialog-digits' | translate }}
      </small>
    </div>
    <div class="flex flex-column align-items-center py-4">
      <p-button
        [label]="'features.homepage.trace-batch' | translate"
        [raised]="true"
        [styleClass]="'primary-custom'"
        [disabled]="!selectedBatchFromList"
        (onClick)="onTraceBatch()"
      ></p-button>
    </div>
  </ng-template>
</p-dialog>
