import { Component, Input } from '@angular/core';

import * as echarts from 'echarts';
import { ECharts } from 'echarts';
import { ECBasicOption } from 'echarts/types/src/util/types';
import { TranslateModule } from '@ngx-translate/core';
import { JsonPipe, NgIf } from '@angular/common';

@Component({
  selector: 'app-graph[hasShipment][hasData]',
  standalone: true,
  templateUrl: './graph.component.html',
  styleUrl: './graph.component.scss',
  imports: [
    TranslateModule,
    NgIf,
    JsonPipe,
  ],
})
export class GraphComponent {

  public chart!: ECharts;
  private _option!: ECBasicOption;

  @Input() hasShipment!: boolean
  @Input() hasData!: boolean

  @Input()
  public set option(data: ECBasicOption) {
    this._option = data;
    if (data) {
      this.init();
    }
  }

  public get option(): ECBasicOption {
    return this._option;
  }

  private init(): void {
    this.chart = echarts.init(document.getElementById('mGraph'));
    this.chart.showLoading();
    this.chart.setOption(this._option, true, false);
    this.chart.hideLoading();
  }
}
