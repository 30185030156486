import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SpeciesService } from '../../../species/services/species.service';
import { MessageService, PrimeTemplate } from 'primeng/api';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { RoutingResourceEnum } from '../../../../core/enums/routing-resource-enum';
import { CustomerService } from '../../services/customer.service';
import { ResponseCustomerDto } from '../../dto/response-customer.dto';
import { UpsertCustomerDto } from '../../dto/upsert-customer.dto';
import { Button } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputTextModule } from 'primeng/inputtext';
import { LabelDescriptionCustomComponent } from '../../../../shared/components/label-description-custom/label-description-custom.component';
import { NgForOf, NgIf } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { UploadButtonCustomComponent } from '../../../../shared/components/upload-button-custom/upload-button-custom.component';
import { BuyerTypeEnum } from '../../enums/buyer-type-enum';
import { InputNumberModule } from 'primeng/inputnumber';

@Component({
  selector: 'app-upsert-customer',
  standalone: true,
  imports: [
    Button,
    DialogModule,
    DropdownModule,
    FloatLabelModule,
    FormsModule,
    InputTextModule,
    LabelDescriptionCustomComponent,
    NgIf,
    PrimeTemplate,
    ReactiveFormsModule,
    TranslateModule,
    UploadButtonCustomComponent,
    InputNumberModule,
    NgForOf,
  ],
  templateUrl: './upsert-customer.component.html',
  styleUrl: './upsert-customer.component.scss',
})
export class UpsertCustomerComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private customerService: CustomerService,
    private messageService: MessageService,
    private translateService: TranslateService,
  ) {
    this.route.paramMap.subscribe((params) => {
      this.customerId = params.get('id') ?? '';
    });
  }

  buyerTypes!: { label: string; value: string }[];
  customerForm!: FormGroup;
  customerId!: string;
  buyerTypeLabel: string = '';
  businessNameLabel: string = '';
  customer!: ResponseCustomerDto;
  dialogGoBackVisible: boolean = false;
  dialogUpsertVisible: boolean = false;

  ngOnInit(): void {
    this.initForm();
    this.buyerTypes = Object.values(BuyerTypeEnum).map((value) => ({
      label: this.translateService.instant(`buyerTypeEnum.label.${value}`),
      value: value.toLowerCase(),
    }));
    if (this.customerId) {
      this.getCustomer();
    }
  }

  initForm(): void {
    this.customerForm = new FormGroup({
      buyerType: new FormControl(null, Validators.required),
      businessName: new FormControl(null, Validators.required),
      address: new FormControl(null, Validators.required),
      stores: new FormControl(null, [
        Validators.required,
        Validators.min(1),
        this.integerValidator,
      ]),
    });
  }

  integerValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (!Number.isInteger(value)) {
      return { notInteger: true };
    }
    return null;
  }

  getCustomer(): void {
    if (this.customerId) {
      this.customerService.getCustomerById(this.customerId).subscribe({
        next: (response: ResponseCustomerDto) => {
          this.customer = response;
          this.buyerTypeLabel = response.buyerType;
          this.businessNameLabel = response.businessName;
          this.customerForm.patchValue({
            buyerType: this.customer.buyerType,
            businessName: this.customer.businessName,
            address: this.customer.address,
            stores: this.customer.numberOfStores,
          });
        },
        error: (err) => {},
      });
    }
  }

  onUpsertCustomer(): void {
    if (this.customerForm.valid) {
      let payload: UpsertCustomerDto;
      let summary = this.translateService.instant('shared.success');
      let detailCreate = this.translateService.instant(
        'shared.create-product-success',
      );
      let detailUpdate = this.translateService.instant(
        'shared.update-product-success',
      );
      if (this.customerId) {
        payload = {
          id: this.customerId,
          buyerType: this.customerForm.get('buyerType')?.value.toLowerCase(),
          businessName: this.customerForm.get('businessName')?.value,
          address: this.customerForm.get('address')?.value,
          numberOfStores: this.customerForm.get('stores')?.value,
        };
        this.customerService.updateCustomer(payload).subscribe({
          next: (response: ResponseCustomerDto) => {
            this.messageService.add({
              severity: 'success',
              summary: summary,
              detail: detailUpdate,
              styleClass: 'custom-toast-success',
            });

            setTimeout(() => {
              this.goBack();
            }, 1000);
          },
          error: (err) => {
            this.closeDialogConfirm();
          },
        });
      } else {
        payload = {
          buyerType: this.customerForm.get('buyerType')?.value.toLowerCase(),
          businessName: this.customerForm.get('businessName')?.value,
          address: this.customerForm.get('address')?.value,
          numberOfStores: this.customerForm.get('stores')?.value,
        };
        this.customerService.createCustomer(payload).subscribe({
          next: (response: ResponseCustomerDto) => {
            this.messageService.add({
              severity: 'success',
              summary: summary,
              detail: detailCreate,
              styleClass: 'custom-toast-success',
            });

            setTimeout(() => {
              this.goBack();
            }, 1000);
          },
          error: (err) => {
            this.closeDialogConfirm();
          },
        });
      }
    }
  }

  closeDialogConfirm(): void {
    this.dialogUpsertVisible = false;
  }

  closeDialogBack(): void {
    this.dialogGoBackVisible = false;
  }

  openDialogConfirm(): void {
    this.dialogUpsertVisible = true;
  }

  openDialogBack(): void {
    if (!this.customerId) {
      if (!this.customerForm.touched) {
        this.goBack();
      } else {
        this.dialogGoBackVisible = true;
      }
    } else {
      if (this.customerForm.pristine) {
        this.goBack();
      } else {
        this.dialogGoBackVisible = true;
      }
    }
  }

  goBack(): void {
    void this.router.navigate([RoutingResourceEnum.CUSTOMERS]);
  }
}
