import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { Ripple } from 'primeng/ripple';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { CalendarModule } from 'primeng/calendar';
import { PrimeNGConfig } from 'primeng/api';
import { LoginComponent } from './core/components/login/login.component';
import { HeaderComponent } from './core/components/header/page/header.component';
import { StorageService } from './core/services/storage.service';
import { NgIf } from '@angular/common';
import { RoutingResourceEnum } from './core/enums/routing-resource-enum';
import { ToastModule } from 'primeng/toast';
import { LangEnum } from './core/enums/lang.enum';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    Ripple,
    TranslateModule,
    FormsModule,
    CalendarModule,
    LoginComponent,
    HeaderComponent,
    NgIf,
    ToastModule,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  isLoginPage = false;
  languages: { label: string; value: string }[] = [];
  selectedLanguage!: string;

  constructor(
    private router: Router,
    private config: PrimeNGConfig,
    public translate: TranslateService,
    protected storage: StorageService,
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.isLoginPage = event.url === `/${RoutingResourceEnum.LOGIN}`;
      }
    });
  }

  ngOnInit(): void {
    this.setupLanguages();
    this.getBrowserLanguage();
    this.translate
      .get('primeng')
      .subscribe((res) => this.config.setTranslation(res));
  }

  private setupLanguages(): void {
    this.languages = [
      { label: 'core.pages.header.names.en', value: LangEnum.ENGLISH },
      { label: 'core.pages.header.names.it', value: LangEnum.ITALIAN },
      { label: 'core.pages.header.names.el', value: LangEnum.GREEK },
    ];
    this.translate.addLangs(this.languages.map((lang) => lang.value));
    this.translate.setDefaultLang(LangEnum.ENGLISH);
  }

  private getBrowserLanguage(): void {
    const browserLang = this.translate.getBrowserLang() as string;
    const langRegexp = new RegExp(Object.values(LangEnum).join('|'));
    this.translate.use(
      browserLang.match(langRegexp) ? browserLang : LangEnum.ENGLISH,
    );
    const language = this.languages.find((lang) => lang.value === browserLang);
    if (language) {
      this.selectedLanguage = language.label;
    }
  }

  changeLanguage(lang: string): void {
    this.translate.use(lang);
    const language = this.languages.find((language) => language.value === lang);
    if (language) {
      this.selectedLanguage = language.label;
    }
  }

  protected readonly RoutingResourceEnum = RoutingResourceEnum;
}
