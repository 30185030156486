import { Injectable } from '@angular/core';
import { catchError, Observable, of, throwError } from 'rxjs';
import { UserModel } from '../../../core/models/user-model';
import { ResponseProducerDto } from '../dto/response-producer.dto';
import { HttpClient } from '@angular/common/http';

import { ApiResourceEnum } from '../../../core/enums/api-resource-enum';
import { BaseApiService } from '../../../core/services/base-api.service';
import { StorageKeyEnum } from '../../../core/enums/storage-key-enum';
import { ResponseProducerFindOneDto } from '../dto/response-producer-find-one.dto';

@Injectable({
  providedIn: 'root',
})
export class ProducerService extends BaseApiService {
  constructor(private http: HttpClient) {
    super();
  }

  getProducers(): Observable<ResponseProducerDto[]> {
    return this.http.get<ResponseProducerDto[]>(
      `${this.baseUrl}/${ApiResourceEnum.PRODUCERS}`,
      {
        headers: this.httpHeaders,
      },
    );
  }

  getProducerById(id: string): Observable<ResponseProducerFindOneDto> {
    return this.http.get<ResponseProducerFindOneDto>(
      `${this.baseUrl}/${ApiResourceEnum.PRODUCERS}/${id}`,
      {
        headers: this.httpHeaders,
      },
    );
  }

  // FIXME: REMOVE IT
  getImage(): Observable<string> {
    const imageUrl = '../../../../assets/images/logo_producer_aqua-de-ma.png';
    return of(imageUrl);
  }
}
