<div class="background-image w-full bg-top-margin">
  <div class="bg-top-margin flex w-full flex-column gap-4 p-6">
    <!-- Top buttons -->
    <div class="flex flex-row justify-content-between">
      <p-button
        [icon]="'pi pi-search'"
        [label]="'features.homepage.trace-batch' | translate"
        [outlined]="true"
        severity="secondary"
        [styleClass]="'secondary-custom'"
        (onClick)="openDialogTraceBatch()"
      />

      <div class="flex gap-4">
        <p-button
          [icon]="'pi pi-plus-circle'"
          [label]="'features.homepage.new-batch' | translate"
          [raised]="true"
          [styleClass]="'primary-custom'"
          (onClick)="goToCreateBatch()"
        />
        <p-button
          [icon]="'pi pi-box'"
          [label]="'features.homepage.new-shipment' | translate | titlecase"
          [raised]="true"
          [styleClass]="'primary-custom'"
          (onClick)="createShipment()"
        />
      </div>
    </div>
    <!-- Left buttons -->
    <div class="flex flex-column lg:flex-row gap-4 w-full h-full">
      <div
        class="flex flex-column justify-content-between w-full gap-4 md:gap-0 lg:w-4"
      >
        <app-card-button-custom
          [title]="'features.homepage.product' | translate"
          [navigateTo]="RoutingResourceEnum.PRODUCTS"
        ></app-card-button-custom>
        <app-card-button-custom
          [title]="'features.homepage.production-site' | translate"
          [navigateTo]="RoutingResourceEnum.PRODUCTION_SITES"
        ></app-card-button-custom>
        <app-card-button-custom
          [title]="'features.homepage.batch' | translate"
          [navigateTo]="RoutingResourceEnum.BATCHES"
        ></app-card-button-custom>
        <app-card-button-custom
          [title]="'features.homepage.customer' | translate"
          [navigateTo]="RoutingResourceEnum.CUSTOMERS"
        ></app-card-button-custom>
      </div>
      <!-- Graphs -->
      <div
        class="flex flex-column bg-graph shadow-2 border-round-md w-full lg:w-8"
      >
        <app-graphs-panel></app-graphs-panel>
      </div>
    </div>
  </div>
</div>
<app-trace-search-custom
  [(dialogVisible)]="dialogVisible"
  [batches]="batches"
  [inputInvalid]="inputInvalid"
  [selectedBatch]="selectedBatch"
></app-trace-search-custom>
