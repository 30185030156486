import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StorageService } from '../services/storage.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private storageService: StorageService,
  ) {
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const token = this.storageService.getToken();


    if (token) {
      const producerId = this.storageService.getProducer()?._id;
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
          'x-producer-id': producerId ?? '',
        },
      });
    }

    return next.handle(req);
  }
}
