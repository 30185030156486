import { Component, OnInit } from '@angular/core';
import { ButtonDirective } from 'primeng/button';
import { PasswordModule } from 'primeng/password';
import { InputTextModule } from 'primeng/inputtext';
import { FloatLabelModule } from 'primeng/floatlabel';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { StyleClassModule } from 'primeng/styleclass';
import { TranslateModule } from '@ngx-translate/core';
import { LoginService } from '../../services/login.service';
import { StorageService } from '../../services/storage.service'; // importa il tuo StorageService
import { LoginModel } from '../../models/login-model';
import { NgIf } from '@angular/common';
import { Router } from '@angular/router';
import { RoutingResourceEnum } from '../../enums/routing-resource-enum';
import { ProfileModel } from '../../models/profile-model';
import { RoleEnum } from '../../enums/role-enum';
import { UploadButtonCustomComponent } from '../../../shared/components/upload-button-custom/upload-button-custom.component';
import { ResponseProducerFindOneDto } from '../../../features/producer/dto/response-producer-find-one.dto';
import { ProducerService } from '../../../features/producer/services/producer.service';
import { HttpStatusCode } from '@angular/common/http';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    ButtonDirective,
    PasswordModule,
    InputTextModule,
    FloatLabelModule,
    FormsModule,
    StyleClassModule,
    TranslateModule,
    ReactiveFormsModule,
    NgIf,
    UploadButtonCustomComponent,
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent implements OnInit {
  loginForm!: FormGroup;
  errorMessage!: string;
  loginError!: boolean;

  constructor(
    private loginService: LoginService,
    private storageService: StorageService,
    private producerService: ProducerService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.loginForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', Validators.required),
    });
  }

  onLogin(): void {
    this.errorMessage = '';
    if (this.loginForm.valid) {
      const { email, password } = this.loginForm.value;
      this.loginService.postLogin(email, password).subscribe({
        next: (response: LoginModel) => {
          this.getUserData(response.accessToken);
          this.loginError = false;
        },
        error: (err) => {
          this.loginError = true;
          if (err.status === HttpStatusCode.Unauthorized) {
            this.errorMessage = 'core.pages.login.errorLogin';
          } else {
            this.errorMessage = 'core.pages.login.error';
          }
        },
      });
    }
  }

  getUserData(token: string): void {
    this.storageService.setToken(token);
    this.loginService.getProfileUser().subscribe({
      next: (profile: ProfileModel) => {
        if (profile.role === RoleEnum.ADMIN) {
          void this.router.navigate([RoutingResourceEnum.PRODUCER_SELECTION], {
            state: { profile },
          });
        } else {
          this.storageService.setProfile(profile);
          this.setProducer(profile.producerId as string);
        }
      },
      error: (err) => {
        console.error('Failed to fetch user data', err);
      },
    });
  }

  setProducer(producerId: string): void {
    if (producerId) {
      this.producerService.getProducerById(producerId).subscribe({
        next: (response: ResponseProducerFindOneDto) => {
          this.storageService.setProducer(response);
          void this.router.navigate([RoutingResourceEnum.HOME]);
        },
      });
    }
  }
}
