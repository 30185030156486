<div class="background-image w-full flex flex-column justify-content-around">
  <app-label-description-custom
    *ngIf="batchId"
    [icon]="'pi pi-pencil'"
    [title]="'features.batch-page.upsert-batch.edit' | translate"
    [subtitle]="'(' + codeLabel + ')'"
  ></app-label-description-custom>
  <app-label-description-custom
    *ngIf="!batchId"
    [icon]="'pi pi-tag'"
    [title]="'features.batch-page.upsert-batch.new-batch' | translate"
  ></app-label-description-custom>
  <div class="flex flex-column w-full align-items-center px-6 flex-grow">
    <div class="flex flex-column justify-content-between height-upsert w-full">
      <div class="card">
        <p-tabView [(activeIndex)]="activeIndex">
          <p-tabPanel
            *ngFor="let tab of tabs; let i = index"
            [disabled]="getTabDisabled(i)"
            [header]="tab.title | translate"
          >
          </p-tabPanel>
        </p-tabView>
      </div>
      <div
        [hidden]="activeIndex !== TabPanelEnum.PROPERTIES"
        class="py-5 h-full"
      >
        <form [formGroup]="batchFormFirst">
          <span class="font-bold py-5">{{
            'features.batch-page.upsert-batch.label-1' | translate
          }}</span>
          <div class="flex flex-column w-full py-3">
            <div class="flex flex-column md:flex-row md:gap-4">
              <div class="w-full p-fluid py-3">
                <p-floatLabel class="w-full">
                  <input formControlName="code" pInputText type="text" />
                  <label>
                    {{
                      'features.batch-page.upsert-batch.code-label' | translate
                    }}
                  </label>
                </p-floatLabel>
              </div>
              <div class="w-full p-fluid py-3">
                <p-floatLabel class="w-full">
                  <p-dropdown
                    optionLabel="name"
                    optionValue="_id"
                    [style]="{ height: '3rem' }"
                    [options]="products"
                    [placeholder]="'shared.search' | translate"
                    formControlName="productId"
                  >
                  </p-dropdown>
                  <label>
                    {{
                      'features.batch-page.upsert-batch.product-label'
                        | translate
                    }}
                  </label>
                </p-floatLabel>
              </div>
              <div class="w-full p-fluid py-3">
                <p-floatLabel class="w-full">
                  <p-dropdown
                    [style]="{ height: '3rem' }"
                    [options]="productionSites"
                    optionLabel="name"
                    optionValue="_id"
                    [placeholder]="'shared.search' | translate"
                    formControlName="productionSiteId"
                  >
                  </p-dropdown>
                  <label>
                    {{
                      'features.batch-page.upsert-batch.production-site-label'
                        | translate
                    }}
                  </label>
                </p-floatLabel>
              </div>
              <div class="w-full p-fluid py-3">
                <p-floatLabel class="w-full">
                  <p-calendar
                    formControlName="seedingDate"
                    [showIcon]="true"
                    [showOnFocus]="false"
                    inputId="buttondisplay"
                  />
                  <label>
                    {{
                      'features.batch-page.upsert-batch.date-seeding-label'
                        | translate
                    }}
                  </label>
                </p-floatLabel>
              </div>
            </div>
            <div class="flex flex-column md:flex-row md:gap-4">
              <div class="w-full p-fluid py-3">
                <p-floatLabel class="w-full">
                  <p-dropdown
                    [style]="{ height: '3rem' }"
                    [options]="typeSeeding"
                    optionValue="value"
                    [placeholder]="'shared.search' | translate"
                    formControlName="seedingType"
                  >
                    <ng-template pTemplate="selectedItem" let-selectedOption>
                      <div>{{ selectedOption.label | translate }}</div>
                    </ng-template>
                    <ng-template let-type pTemplate="item">
                      <div>{{ type.label | translate }}</div>
                    </ng-template>
                  </p-dropdown>
                  <label>
                    {{
                      'features.batch-page.upsert-batch.type-seeding-label'
                        | translate
                    }}
                  </label>
                </p-floatLabel>
              </div>
              <div class="w-full p-fluid py-3">
                <p-floatLabel class="w-full">
                  <p-dropdown
                    [style]="{ height: '3rem' }"
                    [options]="typeGrowingProcess"
                    optionValue="value"
                    [placeholder]="'shared.search' | translate"
                    formControlName="growingProcessType"
                  >
                    <ng-template pTemplate="selectedItem" let-selectedOption>
                      <div>{{ selectedOption.label | translate }}</div>
                    </ng-template>
                    <ng-template let-type pTemplate="item">
                      <div>{{ type.label | translate }}</div>
                    </ng-template>
                  </p-dropdown>
                  <label>
                    {{
                      'features.batch-page.upsert-batch.type-growing-label'
                        | translate
                    }}
                  </label>
                </p-floatLabel>
              </div>
              <div class="w-full p-fluid py-3">
                <p-floatLabel class="w-full">
                  <input formControlName="quantity" pInputText type="number" />
                  <label>
                    {{
                      'features.batch-page.upsert-batch.quantity-label'
                        | translate
                    }}
                  </label>
                </p-floatLabel>
              </div>
              <div class="hidden md:flex w-full p-fluid py-3"></div>
            </div>
          </div>
        </form>
      </div>
      <div
        [hidden]="activeIndex !== TabPanelEnum.CERTIFICATIONS"
        class="py-5 h-full"
      >
        <span class="font-bold py-5">{{
          'features.batch-page.upsert-batch.label-2' | translate
        }}</span>
        <div class="flex flex-column w-full py-3">
          <div class="flex flex-column md:flex-row md:gap-4 md:px-6">
            <div class="w-full p-fluid py-3">
              <label>
                {{
                  'features.batch-page.upsert-batch.certification-1' | translate
                }}
              </label>
              <app-upload-button-custom
                [hasFiles]="hasOGMAttachments"
                [certification]="true"
                [selectedFilesAttachment]="selectedOGMFilesAttachmentFromGet"
                (filesSelected)="onOGMFilesSelected($event)"
                [acceptedFileTypes]="ExtensionsEnum.PDF"
                [maxFileSize]="environment.maxSizeFiles"
                [numberFileLimit]="numberFileLimit"
                [visible]="visibleUploadDialog"
                (fileRemoved)="onFileRemoved($event)"
              ></app-upload-button-custom>
            </div>
            <div class="w-full p-fluid py-3">
              <label>
                {{
                  'features.batch-page.upsert-batch.certification-2' | translate
                }}
              </label>
              <app-upload-button-custom
                [hasFiles]="hasAntibioticAttachments"
                [certification]="true"
                [selectedFilesAttachment]="
                  selectedAntibioticFilesAttachmentFromGet
                "
                (filesSelected)="onAntibioticFilesSelected($event)"
                [acceptedFileTypes]="ExtensionsEnum.PDF"
                [maxFileSize]="environment.maxSizeFiles"
                [numberFileLimit]="numberFileLimit"
                [visible]="visibleUploadDialog"
                (fileRemoved)="onFileRemoved($event)"
              ></app-upload-button-custom>
            </div>
            <div class="w-full p-fluid py-3">
              <label>
                {{
                  'features.batch-page.upsert-batch.certification-3' | translate
                }}
              </label>
              <app-upload-button-custom
                [hasFiles]="hasNutrientsAttachments"
                [certification]="true"
                [selectedFilesAttachment]="selectedNutrientsAttachmentFromGet"
                (filesSelected)="onNutrientsFilesSelected($event)"
                [acceptedFileTypes]="ExtensionsEnum.PDF"
                [maxFileSize]="environment.maxSizeFiles"
                [numberFileLimit]="numberFileLimit"
                [visible]="visibleUploadDialog"
                (fileRemoved)="onFileRemoved($event)"
              ></app-upload-button-custom>
            </div>
          </div>
          <div class="flex flex-row justify-content-center">
            <p
              class="flex py-3 text-xs align-items-center"
              [ngStyle]="{ color: 'rgba(100, 116, 139, 1)' }"
            >
              <i class="pi pi-exclamation-triangle text-yellow-600 mr-2"></i>
              <span class="text-black-alpha-90">
                {{ 'shared.attach-files-content-pdf-only' | translate }}
              </span>
            </p>
          </div>
        </div>
      </div>
      <div
        [hidden]="activeIndex !== TabPanelEnum.IOT_DEVICE"
        class="py-5 h-full"
      >
        <span class="font-bold py-5">{{
          'features.batch-page.upsert-batch.label-3' | translate
        }}</span>
        <div class="flex flex-column w-full py-3">
          <div class="flex flex-column md:flex-row md:gap-4 py-5">
            <div class="w-full p-fluid py-2">
              <label *ngIf="iotDeviceNumber" class="text-gray-300">
                {{
                  'features.batch-page.upsert-batch.iot-label' | translate
                }}</label
              >
              <label class="text-gray-300" *ngIf="!iotDeviceNumber">
                {{
                  'features.batch-page.upsert-batch.iot-label' | translate
                }}</label
              >
              <input pInputText [(ngModel)]="iotDeviceNumber" disabled />

              <p class="text-sm text-gray-300" *ngIf="!iotDeviceNumber">
                {{
                  'features.batch-page.upsert-batch.iot-not-present' | translate
                }}
              </p>
            </div>
            <div class="hidden md:flex w-full p-fluid py-2"></div>
            <div class="hidden md:flex w-full p-fluid py-2"></div>
            <div class="hidden md:flex w-full p-fluid py-2"></div>
          </div>
        </div>
      </div>
      <div
        [hidden]="activeIndex !== TabPanelEnum.NUTRIENTS"
        class="py-5 h-full"
      >
        <form [formGroup]="batchFormFourth">
          <span class="font-bold py-5">
            {{ 'features.batch-page.upsert-batch.label-4' | translate }}
          </span>
          <div
            class="flex flex-column w-full py-3 nutrient-div overflow-y-auto"
          >
            <form [formGroup]="batchFormFourth">
              <div formArrayName="nutriens">
                <div
                  *ngFor="let nutrient of nutrientsControls; let i = index"
                  [formGroupName]="i"
                  class="flex flex-column md:flex-row md:gap-4 pt-5 align-items-center"
                >
                  <i
                    class="pi text-sm"
                    [ngClass]="{
                      'pi-minus-circle': true,
                      'text-gray-400': i === 0,
                      'text-primary': i > 0,
                      'cursor-pointer': i > 0,
                    }"
                    (click)="i > 0 ? removeNutrientField(i) : null"
                    [style.color]="
                      i === 0
                        ? 'rgba(186, 198, 206, 1)'
                        : 'rgba(38, 131, 190, 1)'
                    "
                  ></i>
                  <div class="w-full p-fluid py-3">
                    <p-floatLabel class="w-full">
                      <input
                        pInputText
                        type="text"
                        formControlName="nutrient"
                      />
                      <label>{{
                        'features.batch-page.upsert-batch.nutrient-label'
                          | translate
                      }}</label>
                    </p-floatLabel>
                  </div>
                  <div class="w-full p-fluid py-3">
                    <p-floatLabel class="w-full">
                      <input pInputText type="text" formControlName="value" />
                      <label>{{
                        'features.batch-page.upsert-batch.value-label'
                          | translate
                      }}</label>
                    </p-floatLabel>
                  </div>
                  <i
                    class="pi pi-plus-circle text-sm cursor-pointer"
                    style="color: rgba(38, 131, 190, 1)"
                    (click)="addNutrientField()"
                  ></i>
                  <div class="hidden md:flex w-full p-fluid py-3"></div>
                  <div class="hidden md:flex w-full p-fluid py-3"></div>
                </div>
              </div>
            </form>
          </div>
        </form>
      </div>
      <div class="flex flex-row w-full justify-content-between py-3">
        <p-button
          [icon]="'pi pi-chevron-left'"
          [label]="'shared.back' | translate"
          [outlined]="true"
          severity="secondary"
          [styleClass]="'secondary-custom'"
          (onClick)="handleButtonBackClick()"
        />
        <p-button
          [label]="getButtonLabel() | translate"
          [raised]="true"
          [styleClass]="'primary-custom'"
          [disabled]="isButtonDisabled()"
          (onClick)="handleButtonClick()"
        ></p-button>
      </div>
    </div>
    <p-dialog
      [modal]="true"
      [(visible)]="dialogUpsertVisible"
      [breakpoints]="{ '1199px': '60vw', '575px': '90vw' }"
    >
      <ng-template pTemplate="header">
        <div class="inline-flex align-items-center justify-content-start gap-2">
          <i class="pi pi-exclamation-circle text-white"></i>
          <span class="text-white text-2xl white-space-nowrap">
            {{ 'shared.attention' | translate }}
          </span>
        </div>
      </ng-template>
      <ng-template pTemplate="content">
        <div class="flex flex-column gap-3" *ngIf="!batchId">
          <span
            class="font-bold text-lg text-black-alpha-90 white-space-nowrap"
          >
            {{ 'shared.message-confirm-batch-1' | translate }}
          </span>
          <span class="text-lg text-black-alpha-90 white-space-nowrap">
            {{ 'shared.message-go-back-2' | translate }}
          </span>
        </div>
        <div class="flex flex-column gap-3" *ngIf="batchId">
          <span
            class="font-bold text-lg text-black-alpha-90 white-space-nowrap"
          >
            {{ 'shared.message-update-batch-1' | translate }}
          </span>
          <span class="text-lg text-black-alpha-90 white-space-nowrap">
            {{ 'shared.message-go-back-2' | translate }}
          </span>
        </div>
      </ng-template>
      <ng-template pTemplate="footer">
        <div class="flex flex-row w-full justify-content-end gap-2 p-2">
          <p-button
            [label]="'shared.cancel' | translate"
            [outlined]="true"
            severity="secondary"
            [styleClass]="'secondary-custom'"
            (onClick)="closeDialogConfirm()"
          />
          <p-button
            [label]="'shared.confirm' | translate"
            [raised]="true"
            [styleClass]="'primary-custom'"
            (onClick)="onUpsertBatch()"
          />
        </div>
      </ng-template>
    </p-dialog>
  </div>
</div>
<p-dialog
  [modal]="true"
  [(visible)]="dialogGoBackVisible"
  [style]="{ width: '50rem' }"
  [breakpoints]="{ '1199px': '60vw', '575px': '90vw' }"
>
  <ng-template pTemplate="header">
    <div class="inline-flex align-items-center justify-content-start gap-2">
      <i class="pi pi-exclamation-circle text-white"></i>
      <span class="text-white text-2xl white-space-nowrap">
        {{ 'shared.attention' | translate }}
      </span>
    </div>
  </ng-template>
  <ng-template pTemplate="content">
    <div class="flex flex-column gap-3">
      <span
        class="font-bold text text-black-alpha-90 text-lg white-space-nowrap"
      >
        {{ 'shared.message-go-back-1' | translate }}
      </span>
      <span class="text-lg text text-black-alpha-90 white-space-nowrap">
        {{ 'shared.message-go-back-2' | translate }}
      </span>
    </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <div class="flex flex-row w-full justify-content-end gap-2 p-2">
      <p-button
        [label]="'shared.cancel' | translate"
        [outlined]="true"
        severity="secondary"
        [styleClass]="'secondary-custom'"
        (onClick)="closeDialogBack()"
      />
      <p-button
        type="submit"
        [label]="'shared.confirm' | translate"
        [raised]="true"
        [styleClass]="'primary-custom'"
        (onClick)="goBack()"
      />
    </div>
  </ng-template>
</p-dialog>
