<div
  class="flex flex-column align-items-center justify-content-center h-screen pb-8 login-bg"
>
  <div class="flex flex-column align-items-center pb-6">
    <img
      src="../../../../assets/images/logo.png"
      alt="Logo"
      class="w-4 sm:w-2 xl:w-3 py-4"
    />
    <span class="label1 font-bold text-4xl py-4">NOVAFOODIES</span>
    <span class="text-white text-2xl font-light">Market Place</span>
  </div>
  <form
    [formGroup]="loginForm"
    class="flex flex-column align-items-center w-9 sm:w-6 w-4 gap-4"
    (ngSubmit)="onLogin()"
  >
    <div class="w-full lg:w-9 xl:w-6">
      <p-floatLabel>
        <input pInputText id="email" formControlName="email" />
        <label for="email">{{ 'core.pages.login.username' | translate }}</label>
      </p-floatLabel>
    </div>
    <div class="w-full lg:w-9 xl:w-6">
      <p-floatLabel>
        <p-password
          id="password"
          [style]="{ width: '100%' }"
          [inputStyle]="{
            width: '100%',
            border: '1px solid rgba(38, 131, 190, 1)',
          }"
          formControlName="password"
          [toggleMask]="true"
          [feedback]="false"
        ></p-password>
        <label for="password">Password</label>
      </p-floatLabel>
    </div>
    <div class="w-full xl:w-6 lg:w-9 py-4">
      <button
        type="submit"
        pButton
        label="Login"
        [disabled]="loginForm.invalid"
      ></button>
    </div>
  </form>
  <div
    class="flex flex-column w-9 sm:w-full align-items-center py-4"
    *ngIf="loginError"
  >
    <span class="error-text text-lg text-center font-light"
      >{{ errorMessage | translate }}
    </span>
  </div>
</div>
