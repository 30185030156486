import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Button } from 'primeng/button';
import { TranslateModule } from '@ngx-translate/core';
import { DialogModule } from 'primeng/dialog';
import { FileUpload, FileUploadModule } from 'primeng/fileupload';
import { NgClass, NgForOf, NgIf, NgStyle } from '@angular/common';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { ExtensionsEnum } from '../../../core/enums/extensions-enum';
import { StringifyFileSizePipe } from '../../../core/pipe/stringify-pipe/stringify-file-size.pipe';
import { ResponseAttachmentDto } from '../../../core/models/response-attachment.dto';

@Component({
  selector: 'app-upload-button-custom',
  standalone: true,
  imports: [
    Button,
    TranslateModule,
    DialogModule,
    FileUploadModule,
    NgStyle,
    NgIf,
    ToastModule,
    NgClass,
    NgForOf,
    StringifyFileSizePipe,
  ],
  providers: [MessageService],
  templateUrl: './upload-button-custom.component.html',
  styleUrl: './upload-button-custom.component.scss',
})
export class UploadButtonCustomComponent implements OnInit {
  constructor() {}

  @Input() selectedFilesAttachment: ResponseAttachmentDto[] = [];
  @Output() filesSelected = new EventEmitter<File[]>();
  selectedFiles: File[] = [];
  selectedFilesfromGet: ResponseAttachmentDto[] = [];
  selectedFilesfromAdd: File[] = [];
  @Input() visible: boolean = false;
  onEdit: boolean = false;
  @ViewChild('fileUpload') fileUpload!: FileUpload;
  @Input() disabled!: boolean;
  @Input() certification!: boolean;
  @Input() hasFiles!: boolean;
  @Input() acceptedFileTypes!: string;
  @Input() maxFileSize!: number;
  @Input() numberFileLimit!: number;
  @Output() fileRemoved = new EventEmitter<{ fileKey: any }>();

  ngOnInit(): void {}

  showDialog() {
    if (this.selectedFilesAttachment.length > 0) {
      this.onEdit = true;
      this.onSelect();
    }
    this.visible = true;
  }

  getLabel(): string {
    if (
      this.hasFiles ||
      this.selectedFilesfromGet.length > 0 ||
      this.selectedFilesfromAdd.length > 0
    ) {
      return 'shared.replace';
    } else if (this.certification) {
      return 'shared.certification';
    }
    if (this.acceptedFileTypes === this.ExtensionsEnum.IMAGE) {
      return 'shared.attach-image';
    } else if (this.acceptedFileTypes === this.ExtensionsEnum.PDF) {
      return 'shared.attach-pdf';
    }
    return 'shared.attach';
  }

  hideDialog() {
    this.selectedFiles = [];
    this.visible = false;
  }

  onSelect(event?: any) {
    if (event) {
      this.onEdit = false;
      if (event.currentFiles.length <= this.numberFileLimit) {
        this.selectedFiles = event.currentFiles;
      } else {
        this.selectedFiles = [];
      }
    } else if (!event) {
      if (this.hasFiles) {
        this.selectedFilesfromGet = this.selectedFilesAttachment;
      } else {
      }
    }
  }

  triggerFileInput() {
    this.fileUpload.choose();
  }

  emitFiles() {
    this.filesSelected.emit(this.selectedFiles);
    this.hasFiles = true;
    this.selectedFilesfromAdd = this.selectedFiles;
    this.hideDialog();
  }

  onRemoveTemplatingFileUploaded(index: number) {
    if (index > -1 && this.selectedFiles) {
      this.selectedFiles.splice(index, 1);
      if (this.selectedFiles.length === 0) {
        this.hasFiles = false;
      }
    }
  }

  onRemoveTemplatingFileAdd(index: number) {
    if (index > -1 && this.selectedFilesfromAdd) {
      this.selectedFilesfromAdd.splice(index, 1);
      if (this.selectedFilesfromAdd.length === 0) {
        this.hasFiles = false;
      }
    }
  }

  onRemoveTemplatingFileGet(index: number) {
    if (index > -1 && this.selectedFilesfromGet) {
      const fileToRemove = this.selectedFilesfromGet[index];
      if (fileToRemove?.fileKey) {
        this.fileRemoved.emit({ fileKey: fileToRemove.fileKey });
      }
      this.selectedFilesfromGet.splice(index, 1);
      if (this.selectedFilesfromGet.length === 0) {
        this.onEdit = false;
        this.hasFiles = false;
      }
    }
  }

  protected readonly ExtensionsEnum = ExtensionsEnum;
}
