import { Injectable } from '@angular/core';
import { BaseApiService } from '../../../core/services/base-api.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IndexResponseProductionSiteDto } from '../../production-sites/dto/index-response-production-site.dto';
import { ApiResourceEnum } from '../../../core/enums/api-resource-enum';
import { ApiActionEnum } from '../../../core/enums/api-action-enum';
import { RangeDto } from '../../shipment/dto/range.dto';

@Injectable({
  providedIn: 'root',
})
export class QrCodesService extends BaseApiService {
  constructor(private http: HttpClient) {
    super();
  }

  getQrCodeRange(quantity: number): Observable<RangeDto> {
    return this.http.get<RangeDto>(
      `${this.baseUrl}/${ApiResourceEnum.QR_CODES}/${ApiActionEnum.RANGE}/${quantity}`,
      {
        headers: this.httpHeaders,
      },
    );
  }
}
