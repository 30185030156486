import { Component, OnInit } from '@angular/core';
import { CalendarModule } from 'primeng/calendar';
import { CardButtonCustomComponent } from '../../../shared/components/card-button-custom/card-button-custom.component';
import { RoutingResourceEnum } from '../../enums/routing-resource-enum';
import { TranslateModule } from '@ngx-translate/core';
import { NgClass, NgIf, TitleCasePipe } from '@angular/common';
import { Router } from '@angular/router';
import { RoutingActionEnum } from '../../enums/routing-action-enum';
import { DialogModule } from 'primeng/dialog';
import { FilterSearchCustomComponent } from '../../../shared/components/filter-search-custom/filter-search-custom.component';
import { ResponseBatchDto } from '../../../features/batch/dto/response-batch.dto';
import { BatchService } from '../../../features/batch/services/batch.service';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { FloatLabelModule } from 'primeng/floatlabel';
import { TraceSearchCustomComponent } from '../../../shared/components/trace-search-custom/trace-search-custom.component';
import { GraphsPanelComponent } from '../graphs-panel/graphs-panel.component';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    CalendarModule,
    CardButtonCustomComponent,
    TranslateModule,
    NgClass,
    DialogModule,
    FilterSearchCustomComponent,
    AutoCompleteModule,
    FormsModule,
    NgIf,
    DropdownModule,
    FloatLabelModule,
    ReactiveFormsModule,
    TraceSearchCustomComponent,
    GraphsPanelComponent,
    TitleCasePipe,
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
})
export class HomeComponent implements OnInit {
  dialogVisible: boolean = false;
  batches!: ResponseBatchDto[];
  inputInvalid: boolean = false;
  selectedBatch!: ResponseBatchDto;

  constructor(
    private router: Router,
    private batchService: BatchService,
  ) {}

  ngOnInit(): void {}

  getBatches() {
    this.batchService.getBatches().subscribe({
      next: (batches: ResponseBatchDto[]) => {
        this.batches = batches;
      },
    });
  }

  goToCreateBatch() {
    void this.router.navigate(
      [`${RoutingResourceEnum.BATCHES}/${RoutingActionEnum.INSERT}`],
      {
        state: { originRoute: RoutingResourceEnum.HOME },
      },
    );
  }

  openDialogTraceBatch() {
    this.dialogVisible = true;
    this.getBatches();
  }

  protected readonly RoutingResourceEnum = RoutingResourceEnum;

  createShipment() {
    void this.router.navigate([
      `${RoutingResourceEnum.SHIPMENTS}/${RoutingActionEnum.INSERT}`,
    ]);
  }
}
