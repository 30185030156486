import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiResourceEnum } from '../../../core/enums/api-resource-enum';
import { ApiActionEnum } from '../../../core/enums/api-action-enum';
import { BaseApiService } from '../../../core/services/base-api.service';
import { HttpClient } from '@angular/common/http';
import { IndexResponseProductionSiteDto } from '../dto/index-response-production-site.dto';
import { ResponseProductionSiteDto } from '../dto/response-production-site.dto';
import { UpsertProductionSiteDto } from '../dto/upsert-production-site.dto';

@Injectable({
  providedIn: 'root',
})
export class ProductionSiteService extends BaseApiService {
  constructor(private http: HttpClient) {
    super();
  }

  index(): Observable<IndexResponseProductionSiteDto[]> {
    return this.http.get<IndexResponseProductionSiteDto[]>(
      `${this.baseUrl}/${ApiResourceEnum.PRODUCTION_SITES}/${ApiActionEnum.INDEX}`,
      {
        headers: this.httpHeaders,
      },
    );
  }

  getProductionSites(): Observable<ResponseProductionSiteDto[]> {
    return this.http.get<ResponseProductionSiteDto[]>(
      `${this.baseUrl}/${ApiResourceEnum.PRODUCTION_SITES}`,
      {
        headers: this.httpHeaders,
      },
    );
  }

  getProductionSiteById(id: string): Observable<ResponseProductionSiteDto> {
    return this.http.get<ResponseProductionSiteDto>(
      `${this.baseUrl}/${ApiResourceEnum.PRODUCTION_SITES}/${id}`,
      {
        headers: this.httpHeaders,
      },
    );
  }

  postCreateProductionSite(
    productionSite: UpsertProductionSiteDto,
  ): Observable<ResponseProductionSiteDto> {
    const formData = this.toFormData(productionSite);
    return this.http.post<ResponseProductionSiteDto>(
      `${this.baseUrl}/${ApiResourceEnum.PRODUCTION_SITES}`,
      formData,
    );
  }

  getAttachment(id: string, fileKey: any): Observable<string> {
    return this.http.get(
      `${this.baseUrl}/${ApiResourceEnum.PRODUCTION_SITES}/${id}/file-url/${fileKey}`,
      this.textOptions,
    );
  }

  deleteAttachment(id: string, fileKey: any): Observable<void> {
    return this.http.delete<void>(
      `${this.baseUrl}/${ApiResourceEnum.PRODUCTION_SITES}/${id}/file-url/${fileKey}`,
      {
        headers: this.httpHeaders,
      },
    );
  }

  patchUpdateProductionSite(
    productionSite: UpsertProductionSiteDto,
  ): Observable<ResponseProductionSiteDto> {
    const formData = this.toFormData(productionSite);
    return this.http.patch<ResponseProductionSiteDto>(
      `${this.baseUrl}/${ApiResourceEnum.PRODUCTION_SITES}/${productionSite.id}`,
      formData,
    );
  }

  private toFormData(productionSite: UpsertProductionSiteDto): FormData {
    const formData = new FormData();
    formData.append('name', productionSite.name);
    formData.append('place', productionSite.place);
    formData.append('type', productionSite.type);
    formData.append('capacity', productionSite.capacity);
    formData.append('description', productionSite.description);
    formData.append('position[lat]', productionSite.position.lat.toString());
    formData.append('position[lng]', productionSite.position.lng.toString());

    if (productionSite.files) {
      productionSite.files.forEach((file) => {
        formData.append('files', file, file.name);
      });
    }

    return formData;
  }
}
