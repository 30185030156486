<div
  class="flex flex-column align-items-center justify-content-center h-screen pb-8 product-bg"
>
  <div class="flex flex-column align-items-center pb-6">
    <img
      src="../../../../../assets/images/logo.png"
      alt="Logo"
      class="w-4 sm:w-2 xl:w-3 py-4"
    />
    <span class="label1 font-bold text-4xl py-4">NOVAFOODIES</span>
    <span class="text-white text-2xl font-light">Market Place</span>
  </div>
  <form
    [formGroup]="formGroup"
    class="flex flex-column align-items-center w-9 sm:w-6 w-4 gap-4"
    (ngSubmit)="onLogin()"
  >
    <div class="w-full p-fluid lg:w-9 xl:w-6">
      <p-dropdown
        formControlName="selectedProducer"
        [options]="producers"
        optionLabel="name"
        [placeholder]="'core.pages.producer-select.producer' | translate"
      />
    </div>
    <div class="w-full xl:w-6 lg:w-9 py-4">
      <button
        type="submit"
        pButton
        label="Login"
        [disabled]="formGroup.invalid"
      ></button>
    </div>
  </form>
  <div class="flex flex-column w-9 sm:w-full align-items-center py-4">
    <span
      class="back-login text-md text-center font-light hover:underline"
      (click)="goLogin()"
      >{{ 'core.pages.login.back-login' | translate }}
    </span>
  </div>
</div>
