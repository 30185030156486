import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LangEnum } from '../enums/lang.enum';
import { LocaleCodeType, LocaleEnGB, mapLangLocale } from '../utils/locale-utils';


/**
 * If you want to change the locale of variables already rendered without reloading:
 * https://blog.theodo.com/2021/10/angular-localized-date-pipe/
 */
@Injectable({
  providedIn: 'root',
})
export class LocaleSwitcherService {

  locale!: LocaleCodeType;

  constructor(private transService: TranslateService) {
    //  e.g. "it"
    if (this.transService.getBrowserCultureLang() === this.transService.getBrowserLang()) {
      const browserLang = this.transService.getBrowserLang() as LangEnum;
      const langRegexp = new RegExp(Array.from(mapLangLocale.keys()).join('|'));
      this.locale = browserLang.match(langRegexp) ? mapLangLocale.get(browserLang) as LocaleCodeType : LocaleEnGB;
    }
    // e.g. "it-IT"
    else {
      const browserLocale = this.transService.getBrowserCultureLang() as LocaleCodeType;
      const localeRegexp = new RegExp(Array.from(mapLangLocale.values()).join('|'));
      this.locale = browserLocale.match(localeRegexp) ? browserLocale : LocaleEnGB;
    }
  }

  setLocale(locale: LocaleCodeType): void {
    this.locale = locale;
  }

  setLocaleFromLang(lang: LangEnum): void {
    const locale = mapLangLocale.get(lang);
    this.locale = locale ?? LocaleEnGB;
  }

  getLocale(): LocaleCodeType {
    return this.locale;
  }

}
