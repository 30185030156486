<p-toast position="bottom-right"></p-toast>
<label
  *ngIf="!certification && acceptedFileTypes === ExtensionsEnum.PDF"
  [ngClass]="{
    'disabled-section': disabled,
  }"
>
  {{ 'shared.pdf-upload' | translate }} </label
><label
  *ngIf="!certification && acceptedFileTypes === ExtensionsEnum.IMAGE"
  [ngClass]="{
    'disabled-section': disabled,
  }"
>
  {{ 'shared.image-upload' | translate }}
</label>
<p-button
  [label]="getLabel() | translate"
  [outlined]="true"
  severity="secondary"
  [styleClass]="'secondary-custom'"
  (onClick)="showDialog()"
  [disabled]="disabled"
/>
<p-dialog
  [modal]="true"
  [(visible)]="visible"
  [style]="{ width: '40rem' }"
  [breakpoints]="{ '1199px': '65vw', '575px': '80vw' }"
  [maximizable]="true"
>
  <ng-template pTemplate="header">
    <div class="inline-flex align-items-center justify-content-start gap-2">
      <i class="pi pi-file-o text-white"></i>
      <span class="text-white text-2xl white-space-nowrap">
        {{ 'shared.attach-files-header' | translate }}
      </span>
    </div>
  </ng-template>
  <ng-template pTemplate="content">
    <p-fileUpload
      #fileUpload
      name="file"
      [multiple]="numberFileLimit > 1"
      [accept]="acceptedFileTypes"
      [maxFileSize]="maxFileSize"
      [fileLimit]="numberFileLimit"
      (onSelect)="onSelect($event)"
      customUpload="true"
    >
      <ng-template pTemplate="file"></ng-template>
      <ng-template pTemplate="content" let-files>
        <div
          class="flex align-items-center justify-content-center cursor-pointer flex-column"
          (click)="triggerFileInput()"
          [ngClass]="{
            disabled:
              selectedFiles.length >= numberFileLimit ||
              selectedFilesfromAdd.length >= numberFileLimit ||
              selectedFilesAttachment.length >= numberFileLimit,
          }"
        >
          <i
            [ngStyle]="{ color: '#000' }"
            class="pi pi-cloud-upload p-2 text-2xl text-black-alpha-90 text-400 border-400"
          ></i>
          <p class="mt-4 mb-0">
            <span class="underline font-bold">
              {{ 'shared.attach-files-content-click' | translate }}</span
            >
            <span> {{ 'shared.attach-files-content-drop' | translate }}</span>
          </p>
          <p
            class="mt-4 mb-2 text-xs"
            [ngStyle]="{ color: 'rgba(100, 116, 139, 1)' }"
          >
            <span *ngIf="acceptedFileTypes === ExtensionsEnum.PDF">
              {{ 'shared.attach-files-content-pdf-only' | translate }}
            </span>
            <span *ngIf="acceptedFileTypes === ExtensionsEnum.IMAGE">
              {{ 'shared.attach-files-content-img-only' | translate }}
            </span>
          </p>
        </div>
        <div
          *ngIf="files?.length > 0 && files.length <= numberFileLimit"
          class="pt-4"
        >
          <div
            *ngFor="let file of files; let i = index"
            class="flex justify-content-between align-items-center style-upload mb-2"
          >
            <div
              class="flex flex-row align-items-center justify-content-start py-2"
            >
              <div *ngIf="acceptedFileTypes === ExtensionsEnum.PDF">
                <i class="pi pi-file-o px-5" style="color: #2683be"></i>
              </div>
              <div *ngIf="acceptedFileTypes === ExtensionsEnum.IMAGE">
                <i class="pi pi-image px-5" style="color: #2683be"></i>
              </div>
              <div
                class="flex justify-content-start align-items-start gap-4 flex-column"
              >
                <span class="font-bold">{{ file.name }}</span>
                <span class="font-light">{{
                  file.size | stringifyFileSize
                }}</span>
              </div>
            </div>
            <p-button
              icon="pi pi-times"
              (onClick)="onRemoveTemplatingFileUploaded(i)"
              [outlined]="true"
              [rounded]="true"
              [styleClass]="'upload-custom'"
            />
          </div>
        </div>
      </ng-template>
    </p-fileUpload>
    <div *ngIf="hasFiles && onEdit">
      <div
        *ngFor="let file of selectedFilesfromGet; let i = index"
        class="flex justify-content-between align-items-center style-upload my-2"
      >
        <div
          class="flex flex-row align-items-center justify-content-start py-2"
        >
          <div *ngIf="acceptedFileTypes === ExtensionsEnum.PDF">
            <i class="pi pi-file-o px-5" style="color: #2683be"></i>
          </div>
          <div *ngIf="acceptedFileTypes === ExtensionsEnum.IMAGE">
            <i class="pi pi-image px-5" style="color: #2683be"></i>
          </div>
          <div
            class="flex justify-content-start align-items-start gap-4 flex-column"
          >
            <span class="font-bold">{{ file.originalName }}</span>
            <span class="font-light">{{ file.size | stringifyFileSize }}</span>
          </div>
        </div>
        <p-button
          icon="pi pi-times"
          [outlined]="true"
          [rounded]="true"
          [styleClass]="'upload-custom'"
          (onClick)="onRemoveTemplatingFileGet(i)"
        />
      </div>
    </div>
    <div *ngIf="hasFiles && !onEdit">
      <div
        *ngFor="let file of selectedFilesfromAdd; let i = index"
        class="flex justify-content-between align-items-center style-upload my-2"
      >
        <div
          class="flex flex-row align-items-center justify-content-start py-2"
        >
          <div *ngIf="acceptedFileTypes === ExtensionsEnum.PDF">
            <i class="pi pi-file-o px-5" style="color: #2683be"></i>
          </div>
          <div *ngIf="acceptedFileTypes === ExtensionsEnum.IMAGE">
            <i class="pi pi-image px-5" style="color: #2683be"></i>
          </div>
          <div
            class="flex justify-content-start align-items-start gap-4 flex-column"
          >
            <span class="font-bold">{{ file.name }}</span>
            <span class="font-light">{{ file.size | stringifyFileSize }}</span>
          </div>
        </div>
        <p-button
          icon="pi pi-times"
          [outlined]="true"
          [rounded]="true"
          [styleClass]="'upload-custom'"
          (onClick)="onRemoveTemplatingFileAdd(i)"
        />
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <div class="flex flex-row w-full justify-content-between p-2">
      <p-button
        [label]="'shared.cancel' | translate"
        [outlined]="true"
        severity="secondary"
        [styleClass]="'secondary-custom'"
        (onClick)="hideDialog()"
      />
      <p-button
        [label]="'shared.attach' | translate"
        [raised]="true"
        [styleClass]="'primary-custom'"
        (onClick)="emitFiles()"
        [disabled]="selectedFiles.length === 0"
      />
    </div>
  </ng-template>
</p-dialog>
