export class CircularArray<T> {
  private items: T[];
  private currentIndex: number;

  constructor(arr: T[], startIndex: number = 0) {
    this.items = arr;
    this.currentIndex = startIndex;
  }

  next(): T {
    let i = this.currentIndex,
      arr = this.items;
    this.currentIndex = i < arr.length - 1 ? i + 1 : 0;
    return this.current();
  }

  prev(): T {
    let i = this.currentIndex,
      arr = this.items;
    this.currentIndex = i > 0 ? i - 1 : arr.length - 1;
    return this.current();
  }

  current(): T {
    return this.items[this.currentIndex];
  }

  isEmpty(): boolean {
    let arr = this.items;
    return !arr.length;
  }

  isCurrentFirst(): boolean {
    return this.currentIndex === 0;
  }

  isCurrentLast(): boolean {
    let arr = this.items;
    return this.currentIndex === arr.length - 1;
  }

  getElementNumber(): number {
    return this.items.length - this.currentIndex;
  }

  push(item: T): void {
    this.items.push(item);
  }
}
