import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

export abstract class BaseApiService {
  baseUrl!: string;

  public readonly httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    Accept: 'application/json',
  });

  public readonly textOptions = {
    headers: new HttpHeaders({
      Accept: 'text/html, application/xhtml+xml, */*',
      'Content-Type': 'text/plain; charset=utf-8',
    }),
    responseType: 'text' as const,
  };

  protected constructor() {
    this.baseUrl = environment.baseUrl;
  }
}
