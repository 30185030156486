<p-toast position="bottom-right"></p-toast>
<div class="background-image justify-content-around w-full flex flex-column">
  <app-label-description-custom
    [icon]="'pi pi-tag'"
    [title]="'features.product-page.product' | translate | uppercase"
  ></app-label-description-custom>
  <div
    class="flex flex-column w-full align-items-center height-table px-6 flex-grow"
  >
    <div
      class="flex flex-row w-full justify-content-between align-items-center"
    >
      <p-button
        [icon]="'pi pi-plus-circle'"
        [label]="'features.product-page.new-product' | translate"
        [raised]="true"
        [styleClass]="'primary-custom'"
        (click)="goToInsert()"
      />
      <app-filter-search-custom
        [(value)]="searchQuery"
        (valueChange)="table.applyFilterGlobal($event)"
      ></app-filter-search-custom>
    </div>
    <div class="flex flex-column w-full flex-grow">
      <app-table-custom
        #table
        [columns]="cols"
        [data]="dataTable"
        [countLabel]="'features.product-page.products' | translate"
      ></app-table-custom>
    </div>
  </div>
  <div
    class="flex flex-row w-full pl-6 py-4 justify-content-between align-items-center"
  >
    <p-button
      [icon]="'pi pi-chevron-left'"
      [label]="'shared.back' | translate"
      [outlined]="true"
      severity="secondary"
      [styleClass]="'secondary-custom'"
      (click)="goHome()"
    />
  </div>
</div>
<app-dialog-attachment-custom
  [type]="ExtensionsEnum.IMAGE"
  *ngIf="product"
  [title]="product.name"
  [visible]="dialogImageVisible"
  (onClose)="dialogImageVisible = false"
>
  <div class="flex flex-row overflow-x-hidden col-12">
    <div class="flex flex-column col-5 p-2 align-items-start gap-3">
      <div
        *ngFor="let image of image"
        class="flex flex-row gap-4 align-items-center justify-content-between w-full"
      >
        <div class="flex flex-column col-9 p-2 align-items-start gap-3">
          <div class="flex flex-row">
            <i class="pi pi-image text-sm mr-2"></i>
            <span class="font-bold">{{ image.originalName }}</span>
          </div>
          <div class="flex align-items-start gap-2">
            <span>{{ image.size | stringifyFileSize }}</span>
          </div>
        </div>
        <button
          pButton
          icon="pi pi-download"
          [rounded]="true"
          [text]="true"
          (click)="downloadImage()"
          class="ml-auto bg-transparent view-download"
        ></button>
      </div>
    </div>
    <p-divider layout="vertical" />
    <div class="flex flex-column col-6 p-2">
      <img [src]="imageUrl" alt="Image" />
    </div>
  </div>
</app-dialog-attachment-custom>
