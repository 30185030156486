import { Component, OnInit } from '@angular/core';
import { LabelDescriptionCustomComponent } from '../../../../shared/components/label-description-custom/label-description-custom.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DropdownModule } from 'primeng/dropdown';
import { FloatLabelModule } from 'primeng/floatlabel';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { NgClass, NgIf, NgStyle } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { RoutingResourceEnum } from '../../../../core/enums/routing-resource-enum';
import { SpeciesService } from '../../../species/services/species.service';
import { ResponseSpeciesDto } from '../../../species/dto/response-species.dto';
import { ProductService } from '../../services/product.service';
import { Button } from 'primeng/button';
import { UploadButtonCustomComponent } from '../../../../shared/components/upload-button-custom/upload-button-custom.component';
import { DialogModule } from 'primeng/dialog';
import { FileUploadModule } from 'primeng/fileupload';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { ExtensionsEnum } from '../../../../core/enums/extensions-enum';
import { environment } from '../../../../../environments/environment';
import { ResponseProductDto } from '../../dto/response-product.dto';
import { UpsertProductDto } from '../../dto/upsert-product.dto';
import { ResponseAttachmentDto } from '../../../../core/models/response-attachment.dto';

@Component({
  selector: 'app-upsert-product',
  standalone: true,
  imports: [
    LabelDescriptionCustomComponent,
    TranslateModule,
    DropdownModule,
    FloatLabelModule,
    FormsModule,
    InputTextModule,
    NgStyle,
    NgClass,
    ReactiveFormsModule,
    NgIf,
    Button,
    UploadButtonCustomComponent,
    DialogModule,
    FileUploadModule,
    ToastModule,
  ],
  providers: [MessageService],
  templateUrl: './upsert-product.component.html',
  styleUrl: './upsert-product.component.scss',
})
export class UpsertProductComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private speciesService: SpeciesService,
    private productService: ProductService,
    private messageService: MessageService,
    private translate: TranslateService,
  ) {
    this.route.paramMap.subscribe((params) => {
      this.productId = params.get('id') ?? '';
    });
  }

  species!: ResponseSpeciesDto[];
  selectedSpecies?: ResponseSpeciesDto | null;
  productForm!: FormGroup;
  productId!: string;
  product!: ResponseProductDto;
  selectedImagesAttachment: File[] = [];
  selectedImagesAttachmentFromGet: ResponseAttachmentDto[] = [];
  dialogGoBackVisible: boolean = false;
  dialogUpsertVisible: boolean = false;
  hasAttachments: boolean = false;
  hasFileUploaded: boolean = false;
  numberFileLimit: number = 1;
  visibleUploadDialog!: boolean;
  attachmentDeleted: boolean = false;

  ngOnInit(): void {
    this.initForm();
    this.getSpecies();
  }

  initForm(): void {
    this.productForm = new FormGroup({
      speciesId: new FormControl(null, [Validators.required]),
      name: new FormControl(null, Validators.required),
    });

    this.productForm.get('speciesId')?.valueChanges.subscribe((speciesId) => {
      if (speciesId) {
        this.selectedSpecies = this.species.find((i) => i._id === speciesId);
      } else {
        this.selectedSpecies = null;
      }
    });
  }

  getSpecies(): void {
    this.speciesService.getSpecies().subscribe({
      next: (response: ResponseSpeciesDto[]) => {
        //TODO cambiare con forkjoin
        this.species = response;
        if (this.productId) {
          this.getProduct();
        }
      },
      error: (err) => {},
    });
  }

  getProduct(): void {
    if (this.productId) {
      this.productService.getProductById(this.productId).subscribe({
        next: (response: ResponseProductDto) => {
          this.product = response;
          if (response.attachments && response.attachments.length > 0) {
            this.hasAttachments = true;
            this.selectedImagesAttachmentFromGet = response.attachments;
          }
          this.productForm.patchValue({
            speciesId: this.product.speciesId,
            name: this.product.name,
          });
        },
        error: (err) => {},
      });
    }
  }

  onFilesSelected(files: File[]): void {
    this.selectedImagesAttachment = files;
    if (this.productId) {
      this.hasFileUploaded = true;
    }
  }

  onUpsertProduct(): void {
    if (this.productForm.valid) {
      let payload: UpsertProductDto;
      let summary = this.translate.instant('shared.success');
      let detailCreate = this.translate.instant(
        'shared.create-product-success',
      );
      let detailUpdate = this.translate.instant(
        'shared.update-product-success',
      );
      if (this.productId) {
        payload = {
          id: this.productId,
          name: this.productForm.get('name')?.value,
          speciesId: this.productForm.get('speciesId')?.value,
          files: this.selectedImagesAttachment,
        };
        this.productService.patchUpdateProduct(payload).subscribe({
          next: (response: ResponseProductDto) => {
            this.messageService.add({
              severity: 'success',
              summary: summary,
              detail: detailUpdate,
              styleClass: 'custom-toast-success',
            });
            setTimeout(() => {
              this.goBack();
            }, 1000);
          },
          error: (err) => {
            this.closeDialogConfirm();
          },
        });
      } else {
        payload = {
          name: this.productForm.get('name')?.value,
          speciesId: this.productForm.get('speciesId')?.value,
          files: this.selectedImagesAttachment,
        };
        this.productService.postCreateProduct(payload).subscribe({
          next: (response: ResponseProductDto) => {
            this.messageService.add({
              severity: 'success',
              summary: summary,
              detail: detailCreate,
              styleClass: 'custom-toast-success',
            });
            setTimeout(() => {
              this.goBack();
            }, 1000);
          },
          error: (err) => {
            this.closeDialogConfirm();
          },
        });
      }
    }
  }

  onFileRemoved(event: { fileKey: string }) {
    if (this.productId) {
      this.productService
        .deleteAttachment(this.productId, event.fileKey)
        .subscribe({
          next: () => {
            this.product.attachments = this.product.attachments.filter(
              (i) => i.fileKey !== event.fileKey,
            );
            this.attachmentDeleted = true;
          },
          error: (err) => {
            this.visibleUploadDialog = false;
          },
        });
    }
  }

  closeDialogConfirm(): void {
    this.dialogUpsertVisible = false;
  }

  closeDialogBack(): void {
    this.dialogGoBackVisible = false;
  }

  openDialogConfirm(): void {
    this.dialogUpsertVisible = true;
  }

  openDialogBack(): void {
    if (!this.productId) {
      if (!this.productForm.touched) {
        this.goBack();
      } else {
        this.dialogGoBackVisible = true;
      }
    } else {
      if (this.productForm.pristine) {
        this.goBack();
      } else {
        this.dialogGoBackVisible = true;
      }
    }
  }

  goBack(): void {
    void this.router.navigate([RoutingResourceEnum.PRODUCTS]);
  }

  protected readonly ExtensionsEnum = ExtensionsEnum;
  protected readonly environment = environment;

  isButtonDisabled(): boolean {
    return (
      this.productForm.invalid ||
      (this.productForm.pristine &&
        this.selectedImagesAttachment.length === 0 &&
        !this.attachmentDeleted)
    );
  }
}
