<p-toast position="bottom-right"></p-toast>
<div class="background-image justify-content-around w-full flex flex-column">
  <app-label-description-custom
    [icon]="'pi pi-tag'"
    [title]="'features.homepage.production-site' | translate"
  ></app-label-description-custom>
  <div
    class="flex flex-column w-full align-items-center height-table px-6 flex-grow"
  >
    <div
      class="flex flex-row w-full justify-content-between align-items-center"
    >
      <p-button
        [icon]="'pi pi-plus-circle'"
        [label]="
          'features.production-site-page.new-production-site' | translate
        "
        [raised]="true"
        [styleClass]="'primary-custom'"
        (click)="goToInsert()"
      />
      <app-filter-search-custom
        [(value)]="searchQuery"
        (valueChange)="table.applyFilterGlobal($event)"
      ></app-filter-search-custom>
    </div>
    <div class="flex flex-column w-full flex-grow">
      <app-table-custom
        #table
        [columns]="cols"
        [data]="dataTable"
        [countLabel]="'features.production-site-page.sites' | translate"
      ></app-table-custom>
    </div>
  </div>
  <div
    class="flex flex-row w-full pl-6 justify-content-between align-items-center"
  >
    <p-button
      [icon]="'pi pi-chevron-left'"
      [label]="'shared.back' | translate"
      [outlined]="true"
      severity="secondary"
      [styleClass]="'secondary-custom'"
      (click)="goHome()"
    />
  </div>
</div>
<app-dialog-attachment-custom
  [type]="ExtensionsEnum.PDF"
  *ngIf="productionSite"
  [title]="productionSite.name"
  [visible]="dialogAttachmentsVisible"
  (onClose)="dialogAttachmentsVisible = false"
>
  <div
    class="flex flex-row w-full overflow-x-hidden p-0"
    *ngIf="selectedAttachment"
  >
    <div class="flex flex-column col-6 p-1 align-items-start gap-3">
      <div
        *ngFor="let attachment of attachments"
        class="flex flex-row gap-2 align-items-center justify-content-between w-full"
        [ngClass]="{
          'border-selected': attachment.fileKey === selectedAttachmentFileKey,
        }"
      >
        <div
          class="flex flex-column col-12 p-2 align-items-start gap-3 cursor-pointer hover:bg-bluegray-50"
          (click)="openPreview(attachment, false)"
        >
          <div class="flex flex-row">
            <i class="pi pi-file-o text-sm mr-2"></i>
            <span class="font-bold">{{ attachment.originalName }}</span>
          </div>
          <div class="flex align-items-start gap-2">
            <span>{{ attachment.size | stringifyFileSize }}</span>
          </div>
        </div>
      </div>
    </div>
    <p-divider layout="vertical" />
    <div class="flex flex-column col-6 p-2 gap-3">
      <div
        class="flex flex-column align-items-center justify-content-center cursor-pointer h-full hover:bg-bluegray-50"
        *ngIf="selectedAttachment"
        (click)="downloadFile(pdfUrl, selectedAttachmentName)"
      >
        <i
          [ngStyle]="{ color: '#000' }"
          class="pi pi-download p-2 text-2xl text-black-alpha-90 text-400 border-400"
        ></i>
        <p class="flex-column flex mt-4 mb-0 text-center">
          <span class="underline font-bold">
            {{ 'shared.attach-open-file-2' | translate }}</span
          >
        </p>
        <span class="text-xs pt-3"> {{ selectedAttachmentName }} </span>
      </div>
    </div>
  </div>
</app-dialog-attachment-custom>
