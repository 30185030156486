import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import * as maptilersdk from '@maptiler/sdk';
import { config, Map, Marker } from '@maptiler/sdk';
import { GeocodingControl } from '@maptiler/geocoding-control/maptilersdk';
import '@maptiler/sdk/dist/maptiler-sdk.css';
import '@maptiler/geocoding-control/style.css';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-map',
  standalone: true,
  templateUrl: './position-map.component.html',
  styleUrls: ['./position-map.component.scss'],
})
export class PositionMapComponent implements OnInit, AfterViewInit, OnChanges {
  map: Map | undefined;
  marker: Marker | undefined;
  placeholder: string = '38.14560, 20.25386';
  private static readonly DEFAULT_POSITION = {
    lng: 14.223,
    lat: 42.452,
    zoom: 14,
  };

  @Input() latitude!: number;
  @Input() longitude!: number;

  constructor(private http: HttpClient) {}

  @Output() mapClick = new EventEmitter<{ lng: number; lat: number }>();

  ngOnInit(): void {
    config.apiKey = environment.mapTilerApiKey;
  }

  ngAfterViewInit() {
    this.initializeMap();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.map && (changes['latitude'] || changes['longitude'])) {
      this.centerMap(this.latitude, this.longitude);
    }
  }

  initializeMap() {
    const centerLng =
      this.longitude || PositionMapComponent.DEFAULT_POSITION.lng;
    const centerLat =
      this.latitude || PositionMapComponent.DEFAULT_POSITION.lat;

    this.map = new maptilersdk.Map({
      container: 'map',
      center: [centerLng, centerLat],
      zoom: PositionMapComponent.DEFAULT_POSITION.zoom,
    });

    this.marker = new Marker({ color: '#FF0000', draggable: true })
      .setLngLat([centerLng, centerLat])
      .addTo(this.map);

    this.marker.on('dragend', () => {
      if (this.marker) {
        const lngLat = this.marker.getLngLat();
        this.mapClick.emit({ lng: lngLat.lng, lat: lngLat.lat });
      }
    });

    const gc = new GeocodingControl({
      marker: false,
      placeholder: this.placeholder,
      flyTo: {
        zoom: 9,
      },
    });
    this.map.addControl(gc, 'top-left');
    gc.addEventListener('pick', (e: any) => {
      if (this.map && e.detail) {
        const lng = e.detail.center[0];
        const lat = e.detail.center[1];
        this.map.zoomTo(9);
        this.mapClick.emit({ lng: lng, lat: lat });
      }
    });
    this.map.on('click', (event) => {
      if (this.map && event) {
        const { lng, lat } = event.lngLat;
        this.marker?.setLngLat([lng, lat]).addTo(this.map);
        this.mapClick.emit({ lng, lat });
      }
    });
    this.mapClick.emit({ lng: centerLng, lat: centerLat });
  }

  centerMap(lat: number | undefined, lng: number | undefined) {
    if (this.map && lat !== undefined && lng !== undefined) {
      this.map.setCenter([lng, lat]);
      this.marker?.setLngLat([lng, lat]).addTo(this.map);
    }
  }
}
