<div class="search-container">
  <input
    pInputText
    type="text"
    [placeholder]="'shared.search' | translate"
    class="search-input"
    [ngStyle]="{ 'background-color': backgroundColor || null }"
    [ngClass]="{ 'default-background': !backgroundColor }"
    [(ngModel)]="value"
    (input)="onInputChange($event)"
  />
  <i class="pi pi-search search-icon"></i>
</div>
