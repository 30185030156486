export enum ApiResourceEnum {
  PRODUCERS = 'producers',
  PRODUCTS = 'products',
  CUSTOMERS = 'customers',
  BATCHES = 'batchs',
  PRODUCTION_SITES = 'production-sites',
  SPECIES = 'species',
  SHIPMENTS = 'shipments',
  LOGIN = 'user-auth',
  USERS = 'users',
  IOT_DATA = 'iot-data',
  QR_CODES = 'qr-codes',
}
