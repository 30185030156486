import { Injectable } from '@angular/core';
import { delay, map, Observable, switchMap, tap } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiResourceEnum } from '../../../core/enums/api-resource-enum';
import { BaseApiService } from '../../../core/services/base-api.service';
import { ApiActionEnum } from '../../../core/enums/api-action-enum';
import { IndexResponseProductDto } from '../dto/index-response-product.dto';
import { ResponseProductDto } from '../dto/response-product.dto';
import { UpsertProductDto } from '../dto/upsert-product.dto';

@Injectable({
  providedIn: 'root',
})
export class ProductService extends BaseApiService {
  constructor(private http: HttpClient) {
    super();
  }

  index(): Observable<IndexResponseProductDto[]> {
    return this.http.get<IndexResponseProductDto[]>(
      `${this.baseUrl}/${ApiResourceEnum.PRODUCTS}/${ApiActionEnum.INDEX}`,
      {
        headers: this.httpHeaders,
      },
    );
  }

  getProducts(): Observable<ResponseProductDto[]> {
    return this.http.get<ResponseProductDto[]>(
      `${this.baseUrl}/${ApiResourceEnum.PRODUCTS}`,
      {
        headers: this.httpHeaders,
      },
    );
  }

  deleteAttachment(id: string, fileKey: any): Observable<void> {
    return this.http.delete<void>(
      `${this.baseUrl}/${ApiResourceEnum.PRODUCTS}/${id}/file-url/${fileKey}`,
      {
        headers: this.httpHeaders,
      },
    );
  }

  getProductById(id: string): Observable<ResponseProductDto> {
    return this.http.get<ResponseProductDto>(
      `${this.baseUrl}/${ApiResourceEnum.PRODUCTS}/${id}`,
      {
        headers: this.httpHeaders,
      },
    );
  }

  getImage(id: string, fileKey: any): Observable<string> {
    return this.http.get(
      `${this.baseUrl}/${ApiResourceEnum.PRODUCTS}/${id}/file-url/${fileKey}`,
      this.textOptions,
    );
  }

  postCreateProduct(product: UpsertProductDto): Observable<ResponseProductDto> {
    const formData = this.toFormData(product);
    return this.http.post<ResponseProductDto>(
      `${this.baseUrl}/${ApiResourceEnum.PRODUCTS}`,
      formData,
    );
  }

  patchUpdateProduct(
    product: UpsertProductDto,
  ): Observable<ResponseProductDto> {
    const formData = this.toFormData(product);
    return this.http.patch<ResponseProductDto>(
      `${this.baseUrl}/${ApiResourceEnum.PRODUCTS}/${product.id}`,
      formData,
    );
  }

  private toFormData(product: UpsertProductDto): FormData {
    const formData = new FormData();
    formData.append('name', product.name);
    formData.append('speciesId', product.speciesId);
    if (product.files) {
      product.files.forEach((file) => {
        formData.append('files', file, file.name);
      });
    }
    return formData;
  }
}
