import { Component, Input } from '@angular/core';
import { NgClass, NgStyle } from '@angular/common';
import { Router } from '@angular/router';
import { RoutingResourceEnum } from '../../../core/enums/routing-resource-enum';

@Component({
  selector: 'app-card-button-custom[title][navigateTo]',
  standalone: true,
  imports: [NgClass, NgStyle],
  templateUrl: './card-button-custom.component.html',
  styleUrl: './card-button-custom.component.scss',
})
export class CardButtonCustomComponent {
  constructor(private router: Router) {}

  @Input() title!: string;
  @Input() navigateTo!: RoutingResourceEnum;

  onClick() {
    void this.router.navigate([this.navigateTo]);
  }
}
