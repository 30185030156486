<div class="flex flex-column p-5 w-full">
  <div class="flex flex-column mt-2 gap-1">
    <!--    @if (graphOption) {-->

    <div
      class="flex flex-column md:flex-row justify-content-center pb-4 gap-4 md:gap-0 md:justify-content-between"
    >
      <div class="flex flex-row gap-1">
        <span class="font-bold text-2xl text-left">{{
          'features.homepage.data' | translate
        }}</span>
        <span class="font-light text-2xl text-left">{{
          'features.homepage.analysis' | translate
        }}</span>
      </div>
      <div
        class="card flex justify-content-between arrow-card align-items-center"
      >
        <p-button
          icon="pi pi-chevron-left"
          styleClass="arrow-button"
          (onClick)="nextShipment()"
          [disabled]="isNextShipmentDisabled()"
        />
        <span *ngIf="isFilledShipment()" class="font-bold text-2xl">
          {{
            ('core.components.card-graph.shipment' | translate) +
              ' ' +
              selectedShipmentNumber
          }}</span
        >
        <p-button
          icon="pi pi-chevron-right"
          styleClass="arrow-button"
          (onClick)="prevShipment()"
          [disabled]="isPrevShipmentDisabled()"
        />
      </div>
    </div>

    <div
      class="flex flex-column md:flex-row justify-content-center align-items-center gap-4 md:gap-0 md:justify-content-between"
    >
      <div class="flex flex-row gap-1">
        <div class="flex flex-row gap-2">
          <span class="graph-title-prefix">{{
            ('core.components.card-graph.iot-device' | translate) + ' - '
          }}</span
          ><span class="graph-title-suffix">{{
            'core.components.card-graph.' + selectedArrayType | translate
          }}</span>
        </div>
      </div>
      <!--        <div *ngIf="isFilledShipment()" class="card flex justify-content-between align-items-center gap-2">-->
      <!--          <span class="batch-title-prefix">{{ ('core.components.card-graph.batch' | translate) + ': ' }}</span><span class="batch-title-suffix">{{ selectedBatchCode }}</span>-->
      <!--        </div>-->
      <p-dropdown
        [style]="{ height: '2.7rem' }"
        [options]="batches"
        optionValue="_id"
        optionLabel="code"
        [placeholder]="'shared.search' | translate"
        styleClass="graph-dropdown"
        [(ngModel)]="selectedBatch"
        (onChange)="selectBatch()"
      >
      </p-dropdown>
    </div>

    <app-graph
      [option]="graphOption"
      [hasShipment]="isFilledShipment()"
      [hasData]="isFilledData()"
    ></app-graph>

    <span *ngIf="isFilledShipment()" class="shipment-date">{{
      selectedShipmentDate | date
    }}</span>

    <div class="card flex justify-content-center gap-6">
      <p-button
        icon="pi pi-chevron-left"
        styleClass="arrow-button"
        (onClick)="prevGraph()"
      />
      <p-button
        icon="pi pi-chevron-right"
        styleClass="arrow-button"
        (onClick)="nextGraph()"
      />
    </div>

    <!--    } @else {-->
    <!--      <div class="flex justify-content-center">-->
    <!--        <img src="../../../../assets/images/logo.png" alt="Logo" class="w-4" />-->
    <!--      </div>-->
    <!--    }-->
  </div>
</div>
