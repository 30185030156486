import { RoutingResourceEnum } from '../enums/routing-resource-enum';
import { inject } from '@angular/core';
import { StorageService } from '../services/storage.service';
import { Router } from '@angular/router';

export const canActivateLogged = () => {
  const storageService = inject(StorageService);
  const router = inject(Router);
  if (!storageService.getToken()) {
    void router.navigate([RoutingResourceEnum.LOGIN]);
    return false;
  }
  return true;
};

export const canActivateLogin = () => {
  const storageService = inject(StorageService);
  const router = inject(Router);
  if (storageService.getToken()) {
    void router.navigate([RoutingResourceEnum.HOME]);
    return false;
  }
  return true;
};
