import { RoleEnum } from '../enums/role-enum';

export class ProfileModel {
  _id!: string;
  name!: string;
  email!: string;
  role!: RoleEnum;
  isEnabled!: boolean;
  producerId?: string;
  iat!: number;
  exp!: number;
}
