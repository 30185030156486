import { IotDataMeasuredType } from '../types/iot-data-measured.type';

export const IotDataMeasured: IotDataMeasuredType[] = [
  'temperature',
  'humidity',
  'CO2',
  // 'pH',
  'NH3',
  'H2S',
  'CH4',
];
