import { Injectable } from '@angular/core';
import { ProfileModel } from '../models/profile-model';
import { StorageKeyEnum } from '../enums/storage-key-enum';
import { ResponseProducerFindOneDto } from '../../features/producer/dto/response-producer-find-one.dto';
import { catchError, map, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor(private http: HttpClient) {}

  private setItem(key: string, value: string): void {
    const encodedValue = btoa(value);
    sessionStorage.setItem(key, encodedValue);
  }

  private getItem(key: string): string | null {
    const encodedValue = sessionStorage.getItem(key);
    if (encodedValue) {
      return atob(encodedValue);
    }
    return null;
  }

  private removeItem(key: string): void {
    sessionStorage.removeItem(key);
  }

  setToken(value: string): void {
    this.setItem(StorageKeyEnum.TOKEN, value);
  }

  getToken(): string | null {
    return this.getItem(StorageKeyEnum.TOKEN);
  }

  setProfile(profile: ProfileModel): void {
    const data = JSON.stringify(profile);
    this.setItem(StorageKeyEnum.PROFILE, data);
  }

  removeProfile(): void {
    this.removeItem(StorageKeyEnum.PROFILE);
  }

  getProfile(): ProfileModel | null {
    const data = this.getItem(StorageKeyEnum.PROFILE);
    if (data) {
      return Object.assign(new ProfileModel(), JSON.parse(data));
    }
    return null;
  }

  removeToken(): void {
    return this.removeItem(StorageKeyEnum.TOKEN);
  }

  setProducer(producer: ResponseProducerFindOneDto): void {
    const data = JSON.stringify(producer);
    this.setItem(StorageKeyEnum.PRODUCER, data);
  }

  getProducer(): ResponseProducerFindOneDto | null {
    const data = this.getItem(StorageKeyEnum.PRODUCER);
    if (data) {
      return JSON.parse(data) as ResponseProducerFindOneDto;
    }
    return null;
  }

  removeProducer(): void {
    return this.removeItem(StorageKeyEnum.PRODUCER);
  }

  removeAll(): void {
    this.removeToken();
    this.removeProfile();
    this.removeProducer();
  }
}
