<p-toast position="bottom-right"></p-toast>
<div class="background-image justify-content-around w-full flex flex-column">
  <app-label-description-custom
    [icon]="'pi pi-tag'"
    [title]="'features.customer-page.customer' | translate | uppercase"
  ></app-label-description-custom>
  <div
    class="flex flex-column w-full align-items-center height-table px-6 flex-grow"
  >
    <div
      class="flex flex-row w-full justify-content-between align-items-center"
    >
      <p-button
        [icon]="'pi pi-plus-circle'"
        [label]="'features.customer-page.new-customer' | translate"
        [raised]="true"
        [styleClass]="'primary-custom'"
        (click)="goToInsert()"
      />
      <app-filter-search-custom
        [(value)]="searchQuery"
        (valueChange)="table.applyFilterGlobal($event)"
      ></app-filter-search-custom>
    </div>
    <div class="flex flex-column w-full flex-grow">
      <app-table-custom
        #table
        [columns]="cols"
        [data]="dataTable"
        [countLabel]="'features.customer-page.customers' | translate"
      ></app-table-custom>
    </div>
  </div>
  <div
    class="flex flex-row w-full pl-6 justify-content-between align-items-center"
  >
    <p-button
      [icon]="'pi pi-chevron-left'"
      [label]="'shared.back' | translate"
      [outlined]="true"
      severity="secondary"
      [styleClass]="'secondary-custom'"
      (click)="goHome()"
    />
  </div>
</div>
