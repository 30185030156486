import { Injectable } from '@angular/core';
import { BaseApiService } from '../../../core/services/base-api.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiResourceEnum } from '../../../core/enums/api-resource-enum';
import { InsertShipmentDto } from '../dto/insert-shipment.dto';
import { ResponseShipmentDto } from '../dto/response-shipment.dto';
import { ResponseShipmentFindDto } from '../dto/response-shipment-find.dto';

@Injectable({
  providedIn: 'root',
})
export class ShipmentService extends BaseApiService {
  constructor(private http: HttpClient) {
    super();
  }

  createShipment(shipment: InsertShipmentDto): Observable<ResponseShipmentDto> {
    return this.http.post<ResponseShipmentDto>(
      `${this.baseUrl}/${ApiResourceEnum.SHIPMENTS}`,
      shipment,
      {
        headers: this.httpHeaders,
      },
    );
  }

  getShipments(): Observable<ResponseShipmentFindDto[]> {
    return this.http.get<ResponseShipmentFindDto[]>(
      `${this.baseUrl}/${ApiResourceEnum.SHIPMENTS}`,
      {
        headers: this.httpHeaders,
      },
    );
  }
}
