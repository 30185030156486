import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ResponseSpeciesDto } from '../dto/response-species.dto';
import { BaseApiService } from '../../../core/services/base-api.service';
import { ApiResourceEnum } from '../../../core/enums/api-resource-enum';

@Injectable({
  providedIn: 'root',
})
export class SpeciesService extends BaseApiService {
  constructor(private http: HttpClient) {
    super();
  }

  getSpecies(): Observable<ResponseSpeciesDto[]> {
    return this.http.get<ResponseSpeciesDto[]>(
      `${this.baseUrl}/${ApiResourceEnum.SPECIES}`,
      {
        headers: this.httpHeaders,
      },
    );
  }
}
