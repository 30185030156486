import { Injectable } from '@angular/core';
import { catchError, delay, Observable, of, throwError } from 'rxjs';
import { LoginModel } from '../models/login-model';
import { UserModel } from '../models/user-model';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { environment } from '../../../environments/environment.staging';
import { ProfileModel } from '../models/profile-model';
import { ResponseProducerDto } from '../../features/producer/dto/response-producer.dto';
import { BaseApiService } from './base-api.service';
import { ApiResourceEnum } from '../enums/api-resource-enum';
import { ApiActionEnum } from '../enums/api-action-enum';

@Injectable({
  providedIn: 'root',
})
export class LoginService extends BaseApiService {
  constructor(private http: HttpClient) {
    super();
  }

  postLogin(email: string, password: string): Observable<LoginModel> {
    return this.http.post<LoginModel>(
      `${this.baseUrl}/${ApiResourceEnum.LOGIN}/${ApiActionEnum.LOGIN}`,
      {
        email,
        password,
      },
      {
        headers: this.httpHeaders,
      },
    );
  }

  getProfileUser(): Observable<ProfileModel> {
    return this.http.get<ProfileModel>(
      `${this.baseUrl}/${ApiResourceEnum.LOGIN}/${ApiActionEnum.PROFILE_USER}`,
    );
  }

  getUserData(id: string): Observable<UserModel> {
    return this.http.get<UserModel>(
      `${this.baseUrl}/${ApiResourceEnum.USERS}/${id}`,
    );
  }
}
