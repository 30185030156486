import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AssetsService {
  constructor(private http: HttpClient) {}

  getFile(filename: string): Observable<string> {
    const folderPath = 'assets/images';
    return new Observable<string>((observer) => {
      fetch(`${folderPath}/${filename}`)
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.blob();
        })
        .then((blob) => {
          const objectUrl = URL.createObjectURL(blob);
          observer.next(objectUrl);
          observer.complete();
        })
        .catch((error) => {
          observer.error(error);
        });
    });
  }
}
