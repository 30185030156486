<div class="background-image w-full flex flex-column justify-content-around">
  <app-label-description-custom
    *ngIf="customerId"
    [icon]="'pi pi-pencil'"
    [title]="'features.customer-page.upsert-customer.edit' | translate"
    [subtitle]="
      '(' + buyerTypeLabel.toUpperCase() + '-' + businessNameLabel + ')'
    "
  ></app-label-description-custom>
  <app-label-description-custom
    *ngIf="!customerId"
    [icon]="'pi pi-tag'"
    [title]="'features.customer-page.upsert-customer.new-customer' | translate"
  ></app-label-description-custom>
  <form
    [formGroup]="customerForm"
    class="flex flex-column w-full align-items-start justify-content-between height-upsert p-5 flex-grow"
    (ngSubmit)="onUpsertCustomer()"
  >
    <div class="h-full w-full">
      <div class="flex flex-column w-full">
        <span class="font-bold py-5">{{
          'features.customer-page.upsert-customer.label-1' | translate
        }}</span>
        <div class="flex flex-column w-full">
          <div class="flex flex-column md:flex-row md:gap-4">
            <div class="w-full p-fluid md:w-3 py-5">
              <p-dropdown
                [style]="{ height: '3rem' }"
                [options]="buyerTypes"
                optionValue="value"
                [placeholder]="'primeng.aria.selectLabel' | translate"
                formControlName="buyerType"
              >
                <ng-template pTemplate="selectedItem" let-selectedOption>
                  <div>{{ selectedOption.label | translate }}</div>
                </ng-template>
                <ng-template let-type pTemplate="item">
                  <div>{{ type.label | translate }}</div>
                </ng-template>
              </p-dropdown>
            </div>
            <div class="w-full p-fluid md:w-3 py-5">
              <p-floatLabel class="w-full">
                <input pInputText type="text" formControlName="businessName" />
                <label>
                  <!--        for="float-label">-->
                  {{
                    'features.customer-page.upsert-customer.business-name-label'
                      | translate
                  }}</label
                ></p-floatLabel
              >
            </div>
            <div class="w-full p-fluid md:w-3 py-5">
              <p-floatLabel class="w-full">
                <input formControlName="address" pInputText type="text" />
                <label>
                  <!--        for="float-label">-->
                  {{
                    'features.customer-page.upsert-customer.address-label'
                      | translate
                  }}</label
                ></p-floatLabel
              >
            </div>
            <div class="w-full p-fluid md:w-3 py-5">
              <p-floatLabel class="w-full">
                <p-inputNumber formControlName="stores" [showButtons]="true" />
                <label>
                  {{
                    'features.customer-page.upsert-customer.stores-label'
                      | translate
                  }}</label
                ></p-floatLabel
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-row w-full justify-content-between py-2">
      <p-button
        [icon]="'pi pi-chevron-left'"
        [label]="'shared.back' | translate"
        [outlined]="true"
        severity="secondary"
        [styleClass]="'secondary-custom'"
        (onClick)="openDialogBack()"
      />
      <p-button
        [label]="(customerId ? 'shared.update' : 'shared.create') | translate"
        [raised]="true"
        [styleClass]="'primary-custom'"
        [disabled]="customerForm.invalid || customerForm.pristine"
        (onClick)="openDialogConfirm()"
      />
    </div>
    <p-dialog
      [modal]="true"
      [(visible)]="dialogUpsertVisible"
      [breakpoints]="{ '1199px': '60vw', '575px': '90vw' }"
    >
      <ng-template pTemplate="header">
        <div class="inline-flex align-items-center justify-content-start gap-2">
          <i class="pi pi-exclamation-circle text-white"></i>
          <span class="text-white text-2xl white-space-nowrap">
            {{ 'shared.attention' | translate }}
          </span>
        </div>
      </ng-template>
      <ng-template pTemplate="content">
        <div class="flex flex-column gap-3" *ngIf="!customerId">
          <span
            class="font-bold text-lg text-black-alpha-90 white-space-nowrap"
          >
            {{ 'shared.message-confirm-customer-1' | translate }}
          </span>
          <span class="text-lg text-black-alpha-90 white-space-nowrap">
            {{ 'shared.message-go-back-2' | translate }}
          </span>
        </div>
        <div class="flex flex-column gap-3" *ngIf="customerId">
          <span
            class="font-bold text-lg text-black-alpha-90 white-space-nowrap"
          >
            {{ 'shared.message-update-customer-1' | translate }}
          </span>
          <span class="text-lg text-black-alpha-90 white-space-nowrap">
            {{ 'shared.message-go-back-2' | translate }}
          </span>
        </div>
      </ng-template>
      <ng-template pTemplate="footer">
        <div class="flex flex-row w-full justify-content-end gap-2 p-2">
          <p-button
            [label]="'shared.cancel' | translate"
            [outlined]="true"
            severity="secondary"
            [styleClass]="'secondary-custom'"
            (onClick)="closeDialogConfirm()"
          />
          <p-button
            type="submit"
            [label]="'shared.confirm' | translate"
            [raised]="true"
            [styleClass]="'primary-custom'"
          />
        </div>
      </ng-template>
    </p-dialog>
  </form>
</div>
<p-dialog
  [modal]="true"
  [(visible)]="dialogGoBackVisible"
  [style]="{ width: '50rem' }"
  [breakpoints]="{ '1199px': '60vw', '575px': '90vw' }"
>
  <ng-template pTemplate="header">
    <div class="inline-flex align-items-center justify-content-start gap-2">
      <i class="pi pi-exclamation-circle text-white"></i>
      <span class="text-white text-2xl white-space-nowrap">
        {{ 'shared.attention' | translate }}
      </span>
    </div>
  </ng-template>
  <ng-template pTemplate="content">
    <div class="flex flex-column gap-3">
      <span
        class="font-bold text text-black-alpha-90 text-lg white-space-nowrap"
      >
        {{ 'shared.message-go-back-1' | translate }}
      </span>
      <span class="text-lg text text-black-alpha-90 white-space-nowrap">
        {{ 'shared.message-go-back-2' | translate }}
      </span>
    </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <div class="flex flex-row w-full justify-content-end gap-2 p-2">
      <p-button
        [label]="'shared.cancel' | translate"
        [outlined]="true"
        severity="secondary"
        [styleClass]="'secondary-custom'"
        (onClick)="closeDialogBack()"
      />
      <p-button
        type="submit"
        [label]="'shared.confirm' | translate"
        [raised]="true"
        [styleClass]="'primary-custom'"
        (onClick)="goBack()"
      />
    </div>
  </ng-template>
</p-dialog>
