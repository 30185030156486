import { Injectable } from '@angular/core';
import { BaseApiService } from '../../../core/services/base-api.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiResourceEnum } from '../../../core/enums/api-resource-enum';
import { ApiActionEnum } from '../../../core/enums/api-action-enum';
import { UpsertCustomerDto } from '../dto/upsert-customer.dto';
import { ResponseCustomerDto } from '../dto/response-customer.dto';

@Injectable({
  providedIn: 'root',
})
export class CustomerService extends BaseApiService {
  constructor(private http: HttpClient) {
    super();
  }

  index(): Observable<ResponseCustomerDto[]> {
    return this.http.get<ResponseCustomerDto[]>(
      `${this.baseUrl}/${ApiResourceEnum.CUSTOMERS}/${ApiActionEnum.INDEX}`,
      {
        headers: this.httpHeaders,
      },
    );
  }

  getCustomers(): Observable<ResponseCustomerDto[]> {
    return this.http.get<ResponseCustomerDto[]>(
      `${this.baseUrl}/${ApiResourceEnum.CUSTOMERS}`,
      {
        headers: this.httpHeaders,
      },
    );
  }

  getCustomerById(id: string): Observable<ResponseCustomerDto> {
    return this.http.get<ResponseCustomerDto>(
      `${this.baseUrl}/${ApiResourceEnum.CUSTOMERS}/${id}`,
      {
        headers: this.httpHeaders,
      },
    );
  }

  createCustomer(customer: UpsertCustomerDto): Observable<ResponseCustomerDto> {
    return this.http.post<ResponseCustomerDto>(
      `${this.baseUrl}/${ApiResourceEnum.CUSTOMERS}`,
      customer,
    );
  }

  updateCustomer(customer: UpsertCustomerDto): Observable<ResponseCustomerDto> {
    return this.http.patch<ResponseCustomerDto>(
      `${this.baseUrl}/${ApiResourceEnum.CUSTOMERS}/${customer.id}`,
      customer,
    );
  }
}
