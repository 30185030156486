<div class="mGraph-wrapper">
  <div class="mGraph" id="mGraph"></div>

  <span *ngIf="hasShipment && !hasData">
    <img src="assets/images/no_iot_data.svg" alt="Logo" class="no-iot-style" />
    <div class="style-graph-icon">
      {{ 'core.components.card-graph.no_iot_data' | translate }}
    </div>
  </span>

  <span *ngIf="!hasShipment">
    <img src="assets/images/logo_gray.svg" alt="Logo" class="logo-gray-style" />
    <div class="style-graph-icon">
      {{ 'core.components.card-graph.no_shipments' | translate }}
    </div>
  </span>
</div>
