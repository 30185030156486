import { Component, OnInit } from '@angular/core';
import { DropdownModule } from 'primeng/dropdown';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { RoutingResourceEnum } from '../../../enums/routing-resource-enum';
import { ProducerService } from '../../../../features/producer/services/producer.service';
import { ResponseProducerDto } from '../../../../features/producer/dto/response-producer.dto';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonDirective } from 'primeng/button';
import { Router } from '@angular/router';
import { StorageService } from '../../../services/storage.service';
import { DatePipe, NgIf } from '@angular/common';
import { ProfileModel } from '../../../models/profile-model';
import { ResponseProducerFindOneDto } from '../../../../features/producer/dto/response-producer-find-one.dto';

@Component({
  selector: 'app-producer-selection',
  standalone: true,
  imports: [
    DropdownModule,
    ReactiveFormsModule,
    TranslateModule,
    ButtonDirective,
    DatePipe,
    NgIf,
  ],
  templateUrl: './producer-selection.component.html',
  styleUrl: './producer-selection.component.scss',
})
export class ProducerSelectionComponent implements OnInit {
  token!: string | null;
  userData!: ProfileModel;
  producers!: ResponseProducerDto[];
  formGroup!: FormGroup;

  constructor(
    private producerService: ProducerService,
    private router: Router,
    private storageService: StorageService,
  ) {
    const navigation = this.router.getCurrentNavigation();
    if (navigation?.extras?.state) {
      this.userData = navigation.extras.state['profile'];
    }
  }

  ngOnInit(): void {
    if (this.storageService.getToken()) {
      this.token = this.storageService.getToken();
    }
    this.getProducers();
    this.formGroup = new FormGroup({
      selectedProducer: new FormControl<ResponseProducerDto | null>(
        null,
        Validators.required,
      ),
    });
    this.storageService.removeToken();
  }

  getProducers(): void {
    this.producerService.getProducers().subscribe({
      next: (producer: ResponseProducerDto[]) => {
        this.producers = producer;
      },
      error: (err) => {
        console.error('Failed to fetch user data', err);
      },
    });
  }

  onLogin(): void {
    if (this.formGroup.valid) {
      this.storageService.setProfile(this.userData);
      const selectedProducer = this.formGroup.get('selectedProducer')?.value;
      if (this.token) {
        this.storageService.setToken(this.token);
      }
      this.setProducer(selectedProducer._id);
    }
  }

  setProducer(producerId: string): void {
    if (producerId) {
      this.producerService.getProducerById(producerId).subscribe({
        next: (response: ResponseProducerFindOneDto) => {
          this.storageService.setProducer(response);
          void this.router.navigate([RoutingResourceEnum.HOME]);
        },
      });
    }
  }

  goLogin() {
    void this.router.navigate([RoutingResourceEnum.LOGIN]);
  }
}
