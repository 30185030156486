<div
  class="background-image w-full bg-margin-top-table flex flex-column justify-content-center"
  *ngIf="batch"
>
  <app-label-description-custom
    [icon]="'pi pi-search'"
    [title]="'features.trace-batch-page.trace-batch' | translate"
    [subtitle]="'(' + batch.code + ')'"
  ></app-label-description-custom>
  <div class="bg border-round align-items-center justify-content-center">
    <div class="flex flex-column gap-1 p-3">
      <!--      <i [ngClass]="icon" class="pr-2"></i>-->
      <span class="font-bold text-md text-left">
        {{ 'features.trace-batch-page.blockchain' | translate }}
      </span>
      <div class="flex flex-row align-items-center">
        <span class="text-sm text-left">
          {{ 'features.trace-batch-page.status' | translate }}
        </span>
        <img src="assets/images/Ellipse15.svg" class="ml-2" alt="" />
      </div>
    </div>
  </div>
  <div class="p-fluid trace-label-desktop">
    <p-button
      [icon]="'pi pi-search'"
      [label]="'features.homepage.trace-batch' | translate"
      [outlined]="true"
      severity="secondary"
      [styleClass]="'secondary-custom'"
      (onClick)="openDialogTraceBatch()"
    />
  </div>

  <div class="w-12 flex flex-row p-fluid pt-4 lg:pt-6 px-5">
    <div
      class="flex flex-column align-items-center justify-content-center col-4"
    >
      <img src="assets/images/box.svg" alt="" />
      <div class="vertical"></div>
    </div>
    <div
      class="flex flex-column align-items-center justify-content-center col-4"
    >
      <img
        src="assets/images/box.svg"
        alt=""
        [ngClass]="certificationsNumber > 0 ? 'opacity-100' : 'opacity-0'"
      />
      <div class="vertical"></div>
    </div>
    <div
      class="flex flex-column align-items-center justify-content-center col-4"
    >
      <img
        src="assets/images/box.svg"
        alt=""
        [ngClass]="batch.shipments.length > 0 ? 'opacity-100' : 'opacity-0'"
      />
      <div class="vertical"></div>
    </div>
  </div>

  <div class="w-12 p-fluid negative-mt px-5">
    <p-slider [(ngModel)]="valueSlider" [step]="33.33" />
  </div>
  <div class="w-12 flex flex-row p-fluid pt-2 px-5">
    <div class="flex flex-column col-4 justify-content-center">
      <p class="text-sm md:text-md font-bold text-center mb-0">
        {{ 'features.trace-batch-page.seeding' | translate }}
      </p>
      <p class="text-sm md:text-md text-center m-0">
        {{ batch.seedingDate | date }}
      </p>
    </div>

    <p class="text-sm md:text-md font-bold col-4 text-center">
      {{ 'features.trace-batch-page.certifications' | translate | uppercase }}
    </p>
    <p class="text-sm md:text-md font-bold col-4 text-center">
      {{ 'features.trace-batch-page.distribution' | translate }}
    </p>
  </div>

  <div
    class="flex flex-column md:justify-content-around md:flex-row w-full align-items-start p-5 gap-4"
    *ngIf="batch"
  >
    <div class="w-full p-fluid md:w-5">
      <p-panel>
        <ng-template pTemplate="header">
          <div class="flex flex-column gap-3">
            <p class="font-bold text-white m-0">
              {{ 'features.trace-batch-page.seeding' | translate }}
            </p>
            <p class="font-bold text-white m-0">
              {{
                batch.producer.address +
                  ' ' +
                  batch.producer.zipCode +
                  ', ' +
                  batch.producer.city
              }}
            </p>
          </div>
        </ng-template>
        <ng-template pTemplate="content">
          <div class="p-3">
            <div *ngFor="let i of seedingObject">
              <div class="flex flex-row w-full align-items-center py-1">
                <p class="flex col-6 text-left font-bold m-0">
                  {{ i.label | translate }}
                </p>
                <p class="flex col-6 text-left m-0">{{ i.value }}</p>
              </div>
            </div>
          </div>
        </ng-template>
      </p-panel>
    </div>
    <div class="w-full p-fluid md:w-4">
      <p-panel [ngClass]="certificationsNumber === 0 ? 'panel-empty' : ''">
        <ng-template pTemplate="header">
          <div class="flex flex-column gap-3">
            <p class="font-bold text-white m-0">
              {{
                'features.trace-batch-page.certifications'
                  | translate
                  | uppercase
              }}
            </p>
            <p
              class="font-bold text-white m-0"
              *ngIf="certificationsNumber === 1"
            >
              {{
                certificationsNumber +
                  ' ' +
                  ('features.trace-batch-page.certification' | translate)
              }}
            </p>
            <p
              class="font-bold text-white m-0"
              *ngIf="certificationsNumber != 1"
            >
              {{
                certificationsNumber +
                  ' ' +
                  ('features.trace-batch-page.certifications' | translate)
              }}
            </p>
          </div>
        </ng-template>
        <ng-template pTemplate="content">
          <div class="p-3">
            <div *ngIf="certificationsNumber === 0">
              <p class="no-information m-0" style="color: #64748b">
                {{ 'features.trace-batch-page.no-information' | translate }}
              </p>
            </div>
            <div *ngIf="certificationsNumber > 0">
              <div *ngFor="let i of certificationObject">
                <div class="flex flex-row w-full align-items-center py-1">
                  <p class="flex col-9 text-left font-bold m-0">
                    {{ i.label | translate }}
                  </p>
                  <button
                    pButton
                    icon="pi pi-download"
                    [rounded]="true"
                    [text]="true"
                    (click)="openPreview(i.value[0])"
                    class="ml-auto bg-transparent"
                  ></button>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </p-panel>
    </div>
    <div class="w-full p-fluid md:w-5">
      <p-panel [ngClass]="batch.shipments.length === 0 ? 'panel-empty' : ''">
        <ng-template pTemplate="header">
          <div class="flex flex-column gap-3">
            <p class="font-bold text-white m-0">
              {{ 'features.trace-batch-page.distribution' | translate }}
            </p>
            <p
              class="font-bold text-white m-0"
              *ngIf="batch.shipments.length === 1"
            >
              {{
                batch.shipments.length +
                  ' ' +
                  ('features.trace-batch-page.customer' | translate)
              }}
            </p>
            <p
              class="font-bold text-white m-0"
              *ngIf="batch.shipments.length != 1"
            >
              {{
                batch.shipments.length +
                  ' ' +
                  ('features.trace-batch-page.customer' | translate)
              }}
            </p>
          </div>
        </ng-template>
        <ng-template pTemplate="content">
          <p-accordion>
            <p-accordionTab
              *ngFor="let shipment of batch.shipments; let i = index"
              [header]="shipment.customer.businessName.toUpperCase()"
              class="py-4"
              iconPos="end"
            >
              <div *ngIf="distributionObjects.length === 0">
                <p class="no-information m-0" style="color: #64748b">
                  {{ 'features.trace-batch-page.no-information' | translate }}
                </p>
              </div>
              <div *ngIf="distributionObjects.length > 0">
                <div *ngFor="let item of distributionObjects[i]" class="m-0">
                  <div class="flex flex-row w-full align-items-center py-1">
                    <p class="flex col-6 text-left font-bold m-0">
                      {{ item.label | translate }}
                    </p>
                    <p class="flex col-6 text-left m-0">{{ item.value }}</p>
                  </div>
                </div>
              </div>
            </p-accordionTab>
          </p-accordion>
        </ng-template>
      </p-panel>
    </div>
  </div>
  <div class="flex flex-row w-full justify-content-between p-5">
    <p-button
      [icon]="'pi pi-chevron-left'"
      [label]="'shared.back' | translate"
      [outlined]="true"
      severity="secondary"
      [styleClass]="'secondary-custom'"
      (onClick)="goBack()"
    />
    <p-button
      [label]="'shared.generate' | translate"
      [raised]="true"
      [styleClass]="'primary-custom'"
      (onClick)="generatePDF()"
    />
  </div>
</div>
<app-trace-search-custom
  [(dialogVisible)]="dialogVisible"
  [batches]="batches"
  [inputInvalid]="inputInvalid"
  [selectedBatch]="selectedBatch"
></app-trace-search-custom>
