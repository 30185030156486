import { Routes } from '@angular/router';
import { LoginComponent } from './core/components/login/login.component';
import { RoutingResourceEnum } from './core/enums/routing-resource-enum';
import { HomeComponent } from './core/components/home/home.component';
import { canActivateLogged, canActivateLogin } from './core/guards/auth.guard';
import { SearchProductComponent } from './features/product/pages/search/search-product.component';
import { SearchProductionSiteComponent } from './features/production-sites/pages/search/search-production-site.component';
import { SearchCustomerComponent } from './features/customer/pages/search/search-customer.component';
import { RoutingActionEnum } from './core/enums/routing-action-enum';

import { SearchBatchComponent } from './features/batch/pages/search/search-batch.component';
import { ProducerSelectionComponent } from './core/components/pages/producer-selection/producer-selection.component';
import { CreateShipmentComponent } from './features/shipment/pages/create/create-shipment.component';
import { TraceBatchComponent } from './features/trace-batch/trace-batch/trace-batch.component';
import { UpsertProductComponent } from './features/product/pages/upsert/upsert-product.component';
import { UpsertProductionSiteComponent } from './features/production-sites/pages/upsert/upsert-production-site.component';
import { UpsertCustomerComponent } from './features/customer/pages/upsert/upsert-customer.component';
import { UpsertBatchComponent } from './features/batch/pages/upsert/upsert-batch.component';

export const routes: Routes = [
  {
    path: RoutingResourceEnum.LOGIN,
    component: LoginComponent,
    canActivate: [canActivateLogin],
  },
  {
    path: RoutingResourceEnum.PRODUCER_SELECTION,
    component: ProducerSelectionComponent,
    canActivate: [canActivateLogged],
  },
  {
    path: RoutingResourceEnum.HOME,
    component: HomeComponent,
    canActivate: [canActivateLogged],
  },
  {
    path: RoutingResourceEnum.SHIPMENTS,
    canActivate: [canActivateLogged],
    children: [
      {
        path: RoutingActionEnum.INSERT,
        component: CreateShipmentComponent,
      },
    ],
  },
  {
    path: `${RoutingResourceEnum.TRACE_BATCH}/:id`,
    component: TraceBatchComponent,
    canActivate: [canActivateLogged],
  },
  {
    path: RoutingResourceEnum.PRODUCTS,
    canActivate: [canActivateLogged],
    children: [
      {
        path: RoutingActionEnum.SEARCH,
        component: SearchProductComponent,
      },
      {
        path: RoutingActionEnum.INSERT,
        component: UpsertProductComponent,
      },
      {
        path: `${RoutingActionEnum.EDIT}/:id`,
        component: UpsertProductComponent,
      },
    ],
  },
  {
    path: RoutingResourceEnum.PRODUCTION_SITES,
    canActivate: [canActivateLogged],
    children: [
      {
        path: RoutingActionEnum.SEARCH,
        component: SearchProductionSiteComponent,
      },
      {
        path: RoutingActionEnum.INSERT,
        component: UpsertProductionSiteComponent,
      },
      {
        path: `${RoutingActionEnum.EDIT}/:id`,
        component: UpsertProductionSiteComponent,
      },
    ],
  },
  {
    path: RoutingResourceEnum.CUSTOMERS,
    canActivate: [canActivateLogged],
    children: [
      {
        path: RoutingActionEnum.SEARCH,
        component: SearchCustomerComponent,
      },
      {
        path: RoutingActionEnum.INSERT,
        component: UpsertCustomerComponent,
      },
      {
        path: `${RoutingActionEnum.EDIT}/:id`,
        component: UpsertCustomerComponent,
      },
    ],
  },
  {
    path: RoutingResourceEnum.BATCHES,
    canActivate: [canActivateLogged],
    children: [
      {
        path: RoutingActionEnum.SEARCH,
        component: SearchBatchComponent,
      },
      {
        path: RoutingActionEnum.INSERT,
        component: UpsertBatchComponent,
      },
      {
        path: `${RoutingActionEnum.EDIT}/:id`,
        component: UpsertBatchComponent,
      },
    ],
  },
  {
    path: '',
    redirectTo: RoutingResourceEnum.HOME,
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: RoutingResourceEnum.HOME,
  },
];
