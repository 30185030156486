import { LangEnum } from '../enums/lang.enum';
import { CountryEnum } from '../enums/country.enum';

export type LocaleCodeType = `${LangEnum}-${CountryEnum}`; // Example: 'en-US'

export const LocaleEnGB: LocaleCodeType = `${LangEnum.ENGLISH}-${CountryEnum.UNITED_KINGDOM}`;

export const mapLangLocale = new Map<LangEnum, LocaleCodeType>([
  [LangEnum.ITALIAN, `${LangEnum.ITALIAN}-${CountryEnum.ITALY}`],
  [LangEnum.ENGLISH, `${LangEnum.ENGLISH}-${CountryEnum.UNITED_KINGDOM}`],
  [LangEnum.GREEK, `${LangEnum.GREEK}-${CountryEnum.GREECE}`],
]);
