import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { ApiResourceEnum } from '../../../core/enums/api-resource-enum';
import { BaseApiService } from '../../../core/services/base-api.service';
import { ResponseIotDataDto } from '../dto/response-iot-data.dto';
import { ApiActionEnum } from '../../../core/enums/api-action-enum';

@Injectable({
  providedIn: 'root',
})
export class IotDataService extends BaseApiService {
  constructor(private http: HttpClient) {
    super();
  }

  findByDate(date: Date): Observable<ResponseIotDataDto[]> {
    return this.http.get<ResponseIotDataDto[]>(
      `${this.baseUrl}/${ApiResourceEnum.IOT_DATA}/${ApiActionEnum.FILTER}/${date.toISOString()}`,
      {
        headers: this.httpHeaders,
      },
    );
  }
}
