import { ResponseIotDataDto } from '../../../features/iot-data/dto/response-iot-data.dto';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ECBasicOption } from 'echarts/types/src/util/types';
import { IotDataMeasuredType } from '../../../features/iot-data/types/iot-data-measured.type';

abstract class AbstractGraphOption {
  constructor(
    protected translate: TranslateService,
    protected data: ResponseIotDataDto[],
  ) {}

  getOption(): ECBasicOption {
    return {
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: this.getXAxisLabels(),
        axisLabel: {
          fontFamily: 'Archivo',
          fontWeight: '500',
          fontSize: 15,
          color: '#000',
        },
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          formatter: `{value} ${this.unit}`,
          fontFamily: 'Archivo',
          fontWeight: '500',
          fontSize: 14,
          color: '#000',
        },
      },
      tooltip: {
        trigger: 'axis',
      },
      series: [
        {
          type: 'line',
          data: this.getGraphValues(),
          // areaStyle: {}
          areaStyle: {
            // color: 'rgba(255, 0, 0, 0.5)' // Colore rosso con trasparenza
            // color: '#FF0000' // Colore rosso senza trasparenza
          },
        },
      ],
      grid: {
        // left: '10%',
        // right: '10%',
        top: '7%',
        bottom: '7%',
        // containLabel: true,
      },
      // graphic: !this.data.length ? {
      //   type: 'image',
      //   id: 'logo',
      //   style: {
      //     image: 'assets/images/no_information.svg',
      //     width: '200',
      //     height: '200',
      //     opacity: 0.5,
      //   },
      //   left: 'center',
      //   top: 'center',
      //   zlevel: 1,
      //   silent: true,
      // } : null,
    };
  }

  abstract field: IotDataMeasuredType;

  abstract unit: string;

  // abstract areaStyleColor: string;

  getGraphValues(): number[] {
    return this.data.map((i) => i[this.field]);
  }

  getXAxisLabels(): string[] {
    return this.data.map((i) => {
      const d = new Date(i.date);
      return `${d.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
    });
  }
}

class TemperatureOptionStrategy extends AbstractGraphOption {
  constructor(translate: TranslateService, data: ResponseIotDataDto[]) {
    super(translate, data);
  }

  field: IotDataMeasuredType = 'temperature';
  unit = '°C';
}

class HumidityOptionStrategy extends AbstractGraphOption {
  constructor(translate: TranslateService, data: ResponseIotDataDto[]) {
    super(translate, data);
  }

  field: IotDataMeasuredType = 'humidity';
  unit = 'g/m³';
}

class CO2OptionStrategy extends AbstractGraphOption {
  constructor(translate: TranslateService, data: ResponseIotDataDto[]) {
    super(translate, data);
  }

  field: IotDataMeasuredType = 'CO2';
  unit = 'mg/m³';
}

// class PhOptionStrategy extends AbstractGraphOption {
//
//   constructor(translate: TranslateService, data: ResponseIotDataDto[]) {
//     super(translate, data);
//   }
//
//   field: IotDataMeasuredType = 'pH';
//   unit = '';
// }

class NH3OptionStrategy extends AbstractGraphOption {
  constructor(translate: TranslateService, data: ResponseIotDataDto[]) {
    super(translate, data);
  }

  field: IotDataMeasuredType = 'NH3';
  unit = 'mg/m³';
}

class CH4OptionStrategy extends AbstractGraphOption {
  constructor(translate: TranslateService, data: ResponseIotDataDto[]) {
    super(translate, data);
  }

  field: IotDataMeasuredType = 'CH4';
  unit = '% vol';
}

class H2SOptionStrategy extends AbstractGraphOption {
  constructor(translate: TranslateService, data: ResponseIotDataDto[]) {
    super(translate, data);
  }

  field: IotDataMeasuredType = 'H2S';
  unit = 'mg/m³';
}

@Injectable({ providedIn: 'root' })
export class GraphOptionFactoryService {
  constructor(protected translate: TranslateService) {}

  public create<T extends ECBasicOption>(
    type: IotDataMeasuredType,
    data: ResponseIotDataDto[],
  ): T {
    switch (type) {
      case 'temperature':
        return new TemperatureOptionStrategy(
          this.translate,
          data,
        ).getOption() as T;
      case 'humidity':
        return new HumidityOptionStrategy(
          this.translate,
          data,
        ).getOption() as T;
      case 'CO2':
        return new CO2OptionStrategy(this.translate, data).getOption() as T;
      // case 'pH':
      //   return new PhOptionStrategy(this.translate, data).getOption() as T;
      case 'NH3':
        return new NH3OptionStrategy(this.translate, data).getOption() as T;
      case 'H2S':
        return new H2SOptionStrategy(this.translate, data).getOption() as T;
      case 'CH4':
        return new CH4OptionStrategy(this.translate, data).getOption() as T;
      default:
        throw new Error('Invalid type');
    }
  }
}
